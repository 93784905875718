import { useEffect, useState } from 'react';
import { USER_SEARCH_QUERY } from '../../global/gql/queries';
import { Dispatcher, UserData, UsersData } from '../../global/interfaces';
import { useLazyReneQuery } from '../../hooks';
import Search from '../search/search';
import Spinner from '../spinner/spinner';
import User from '../user/user';
import './search-user.scss';

const SearchUser = ({
  gameId,
  disabled,
  excludeCurrent = false,
  isMember = true,
  orgId,
  setLoading,
  setSearchData,
}: {
  gameId?: string;
  disabled?: boolean;
  excludeCurrent?: boolean;
  isMember?: boolean;
  orgId?: string;
  setLoading: (loading: boolean) => void;
  setSearchData: (data: UserData[] | undefined) => void;
}) => {
  const [userSearchTerm, setSearchTerm] = useState('');

  const [search, { data, loading }] = useLazyReneQuery<{ UserSearch: UsersData }>(USER_SEARCH_QUERY);

  useEffect(() => {
    if (userSearchTerm) {
      let variables: {
        userSearchTerm: string;
        gameId?: string;
        excludeCurrent: boolean;
        orgFilter?: { isMember: boolean; orgId: string };
      } = {
        userSearchTerm,
        excludeCurrent,
      };
      variables = gameId ? { ...variables, gameId } : variables;
      variables = orgId ? { ...variables, orgFilter: { isMember, orgId } } : variables;
      search({ variables });
    }
  }, [userSearchTerm, search, gameId, excludeCurrent, isMember, orgId]);

  useEffect(() => {
    if (data) {
      setSearchData(data?.UserSearch?.items);
    }
  }, [data, setSearchData]);

  useEffect(() => {
    setLoading(loading);
  }, [loading, setLoading]);

  useEffect(() => {
    if (disabled && userSearchTerm) setSearchTerm('');
  }, [disabled, userSearchTerm]);

  useEffect(() => {
    if (!userSearchTerm) {
      setSearchData(undefined);
    }
  }, [setSearchData, userSearchTerm]);

  return (
    <Search
      callback={(data: string) => {
        setSearchTerm(data);
      }}
      apiSearch
      disabled={disabled}
    />
  );
};

const SearchUserWithDropdown = ({
  title,
  gameId,
  description,
  disabled,
  errorMessage,
  isMember,
  orgId,
  excludeCurrent,
  setSelectedUser,
}: {
  title?: string;
  description?: string;
  gameId?: string;
  isMember?: boolean;
  orgId?: string;
  setSelectedUser: Dispatcher<UserData | undefined> | ((user: UserData) => void);
  disabled?: boolean;
  errorMessage?: string;
  excludeCurrent?: boolean;
}) => {
  const [data, setSearchData] = useState<UserData[]>();
  const [loading, setLoading] = useState(false);

  return (
    <div className="search-user-dropdown">
      <h3>{title}</h3>
      {description && <p>{description}</p>}
      <SearchUser
        setSearchData={setSearchData}
        setLoading={setLoading}
        gameId={gameId}
        disabled={disabled}
        excludeCurrent={excludeCurrent}
        isMember={isMember}
        orgId={orgId}
      />
      {(loading || data) && (
        <div className="search-user-dropdown__list">
          {loading ? <Spinner size="md" /> : null}
          {!loading && data && data.map((user, i) => <User key={i} user={user} setSelectedUser={setSelectedUser} />)}
        </div>
      )}
      <p className="error-msg">{errorMessage}</p>
    </div>
  );
};

export { SearchUser, SearchUserWithDropdown };
