import { useState } from 'react';
import { Dispatcher } from '../../../global/interfaces';
import Icon from '../../Icon/Icon';
import './preview-offer-modal.scss';

const PreviewOfferModal = ({ setIsModalOpen }: { setIsModalOpen: Dispatcher<string> }) => {
  const [tab, setTab] = useState<'desktop' | 'mobile'>('desktop');

  const handleModalClose = () => {
    setIsModalOpen('');
  };

  return (
    <div className="preview-offer-modal">
      <div className="preview-offer-modal__heading">
        <h1>Preview Offer</h1>
        <button type="button" onClick={handleModalClose}>
          <Icon name="close" size={24} />
        </button>
      </div>
      <div className="preview-offer-modal__tabs tabs">
        <button className={`${tab === 'desktop' ? '--selected' : ''}`} onClick={() => setTab('desktop')}>
          Desktop
        </button>
        <button className={`${tab === 'mobile' ? '--selected' : ''}`} onClick={() => setTab('mobile')}>
          Mobile
        </button>
      </div>
      <div className={`preview-offer-modal__content --${tab}`}>
        <h2>ReneVerse Boost Bundle Bonanza – Enjoy Airdrop SS2</h2>
        <div className="preview-offer-modal__content_sender">
          <img src="" alt="" />
          <span>ReneVerse</span>
        </div>
        <img src="" alt="" />
        <p>
          Lorem ipsum dolor sit amet consectetur. Egestas tincidunt urna risus nullam integer urna pretium nullam amet.
          Eget congue lorem imperdiet non in vitae pharetra. Amet dolor feugiat id elit morbi quam. Id tempor at felis
          cras laoreet.
        </p>
        <button type="button" className="btn-primary-solid">
          Join us now!
        </button>
      </div>
    </div>
  );
};

export default PreviewOfferModal;
