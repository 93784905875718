import React from 'react';
import * as Icons from '../../global/icons';

export type IconName =
  | 'alert'
  | 'arrow-back'
  | 'arrow-forward'
  | 'arrows-sort'
  | 'arrow-down-left-circle'
  | 'assets'
  | 'bell-alert'
  | 'bell'
  | 'calendar'
  | 'checked'
  | 'chain'
  | 'chevron-left'
  | 'chevron-right'
  | 'chevron-down'
  | 'chevron-up'
  | 'circle-check'
  | 'circle-cross'
  | 'close'
  | 'close-box'
  | 'credit'
  | 'discord'
  | 'diamond'
  | 'dots'
  | 'filter'
  | 'gamepad'
  | 'history'
  | 'key'
  | 'man'
  | 'organization'
  | 'open-box'
  | 'plus'
  | 'play'
  | 'search'
  | 'security'
  | 'settings'
  | 'steam'
  | 'upload'
  | 'user'
  | 'user-plus'
  | 'wallet'
  | 'transfer'
  | 'edit'
  | 'bucket'
  | 'file'
  | 'trash'
  | 'stars'
  | 'database'
  | 'template'
  | 'checkbox'
  | 'checkmark'
  | 'folders'
  | 'chatbot'
  | 'exit'
  | 'copy'
  | 'mint'
  | 'bolt'
  | 'download'
  | 'link'
  | 'pip'
  | 'hamburger'
  | 'lock'
  | 'lock-open'
  | 'elipse'
  | 'play-card'
  | 'campaign'
  | 'web2'
  | 'flag'
  | 'flag-f'
  | 'trophy'
  | 'users'
  | 'rene-card-logo'
  | 'eye'
  | 'mail'
  | 'soon'
  | 'rotate'
  | 'referral';

interface Props {
  name: IconName;
  size?: number;
  color?: string;
}

export const SvgIcons = new Map<IconName, JSX.Element>([
  ['alert', <Icons.Alert />],
  ['arrow-back', <Icons.ArrowBack />],
  ['arrow-forward', <Icons.ArrowForward />],
  ['arrows-sort', <Icons.ArrowsSort />],
  ['assets', <Icons.Assets />],
  ['bell-alert', <Icons.BellAlert />],
  ['bell', <Icons.Bell />],
  ['calendar', <Icons.Calendar />],
  ['checked', <Icons.Check />],
  ['chevron-left', <Icons.ChevronLeft />],
  ['chevron-right', <Icons.ChevronRight />],
  ['chevron-down', <Icons.ChevronDown />],
  ['chevron-up', <Icons.ChevronUp />],
  ['circle-check', <Icons.CircleCheck />],
  ['circle-cross', <Icons.CircleCross />],
  ['close', <Icons.Close />],
  ['credit', <Icons.Credit />],
  ['discord', <Icons.Discord />],
  ['filter', <Icons.Filter />],
  ['history', <Icons.History />],
  ['key', <Icons.Key />],
  ['man', <Icons.Man />],
  ['organization', <Icons.Organization />],
  ['plus', <Icons.Plus />],
  ['play', <Icons.Play />],
  ['search', <Icons.Search />],
  ['security', <Icons.Security />],
  ['settings', <Icons.Settings />],
  ['steam', <Icons.Steam />],
  ['upload', <Icons.Upload />],
  ['user', <Icons.User />],
  ['user-plus', <Icons.UserPlus />],
  ['wallet', <Icons.Wallet />],
  ['transfer', <Icons.Transfer />],
  ['edit', <Icons.Edit />],
  ['bucket', <Icons.Bucket />],
  ['file', <Icons.File />],
  ['trash', <Icons.Trash />],
  ['stars', <Icons.Stars />],
  ['database', <Icons.Database />],
  ['template', <Icons.Template />],
  ['checkbox', <Icons.Checkbox />],
  ['checkmark', <Icons.Checkmark />],
  ['folders', <Icons.Folders />],
  ['chatbot', <Icons.Chatbot />],
  ['exit', <Icons.Exit />],
  ['copy', <Icons.Copy />],
  ['mint', <Icons.Mint />],
  ['bolt', <Icons.Bolt />],
  ['download', <Icons.Download />],
  ['link', <Icons.Link />],
  ['pip', <Icons.PIP />],
  ['hamburger', <Icons.Hamburger />],
  ['arrow-down-left-circle', <Icons.ArrowLeftDownCircle />],
  ['gamepad', <Icons.Gamepad />],
  ['dots', <Icons.Dots />],
  ['lock', <Icons.Lock />],
  ['lock-open', <Icons.LockOpen />],
  ['elipse', <Icons.Elipse />],
  ['users', <Icons.Users />],
  ['play-card', <Icons.PlayCard />],
  ['campaign', <Icons.Campaign />],
  ['diamond', <Icons.Diamond />],
  ['web2', <Icons.Web2 />],
  ['flag', <Icons.Flag />],
  ['flag-f', <Icons.TaskFulfilled />],
  ['trophy', <Icons.Trophy />],
  ['referral', <Icons.Referral />],
  ['rene-card-logo', <Icons.ReneCardLogo />],
  ['eye', <Icons.Eye />],
  ['mail', <Icons.Mail />],
  ['chain', <Icons.Chain />],
  ['soon', <Icons.Soon />],
  ['rotate', <Icons.Rotate />],
]);

function Icon({ name, color, size = 22 }: Props) {
  interface svgProps {
    fill?: string;
    width: number;
    height: number;
  }
  const props: svgProps = { width: size, height: size };

  if (color) {
    props.fill = color;
  }
  const iconElement = SvgIcons.get(name);
  return iconElement ? React.cloneElement(iconElement, props) : null;
}

export default Icon;
