import { useLocation } from 'react-router-dom';
import { ChangeEvent, useEffect, useState } from 'react';
import { useErrorContext } from '../../../../context/error-context';
import { debounce, handleError } from '../../../../utils';
import { useFileUpload, useReneMutation, useValidation } from '../../../../hooks';
import { Dispatcher, Event, Refetch, UserData } from '../../../../global/interfaces';
import {
  CHANGE_PASSWORD_MUTATION,
  CONNECT_DISCORD_MUTATION,
  CONNECT_TWITTER_MUTATION,
  DISCONNECT_DISCORD_MUTATION,
  DISCONNECT_TWITTER_MUTATION,
  UPDATE_USERNAME,
  UPDATE_USER_MUTATION,
} from '../../../../global/gql/mutations';
import { passwordValidations, userValidations } from './validations';
import Icon from '../../../../components/Icon/Icon';
import Input from '../../../../components/input/input';
import Spinner from '../../../../components/spinner/spinner';
import EditableImage from '../../../../components/editable-image/editable-image';

import './profile-settings.scss';

const initialState = {
  firstName: '',
  lastName: '',
  username: '',
  email: '',
  twitter: '',
  discord: '',
  image: '',
};

interface Props {
  user: UserData | undefined;
  refetch: Refetch<{
    User: UserData;
  }>;
  setOpenMobileMenu: Dispatcher<boolean>;
}

const ChangePassword = () => {
  const { setError } = useErrorContext();
  const { errors, isFormInvalid } = useValidation(passwordValidations);
  const [form, setUserPassword] = useState({
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  });
  const [wrongPass, setWrongPass] = useState('');

  const [isPassChangeSuccess, setIsPassChangeSuccess] = useState<boolean>(false);

  const [changePassword, { loading }] = useReneMutation(CHANGE_PASSWORD_MUTATION, {
    variables: {
      oldPassword: form.oldPassword,
      newPassword: form.newPassword,
    },
    onCompleted(data: { ChangePassword: boolean }) {
      if (data?.ChangePassword) setIsPassChangeSuccess(true);
    },
    onError(err) {
      if (err.message === 'Password was not matched') {
        return setWrongPass('Wrong password');
      }
      handleError(err, setError);
    },
  });

  const inputHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setUserPassword((prev) => {
      return { ...prev, [e.target.name]: e.target.value.trim() };
    });
  };

  const handleChangePassword = () => {
    if (isPassChangeSuccess) setIsPassChangeSuccess(false);
    if (wrongPass) setWrongPass('');
    if (isFormInvalid(form)) return;
    changePassword();
  };

  return (
    <div className="profile-settings__password">
      <h3 className="rainbow-btn-text">Change Password</h3>
      <div>
        <Input
          label="Current Password"
          name="oldPassword"
          type="password"
          placeholder="Enter current password"
          handleInput={inputHandler}
          value={form.oldPassword}
          errorMessage={errors?.oldPassword || wrongPass}
        />
        <Input
          label="New Password"
          name="newPassword"
          type="password"
          autoComplete="new-password"
          placeholder="Enter new password"
          handleInput={inputHandler}
          value={form.newPassword}
          errorMessage={errors?.newPassword}
        />
        <Input
          name="confirmNewPassword"
          type="password"
          label="Confirm Password"
          placeholder="Confirm new password"
          handleInput={inputHandler}
          value={form.confirmNewPassword}
          errorMessage={errors?.confirmNewPassword}
        />
        <div className="profile-settings__password_submit">
          <button className="primary-btn center-btn" type="submit" onClick={handleChangePassword}>
            {loading ? <Spinner size="md" /> : 'Change Password'}
          </button>
          {isPassChangeSuccess && <p>Password successfully changed!</p>}
        </div>
      </div>
    </div>
  );
};

const ProfileSettings: React.FC<Props> = ({ user, refetch, setOpenMobileMenu }) => {
  let errorSocialConnect: string | null = '';
  const uploadFile = useFileUpload();
  const location = useLocation();
  const { errors, isFormInvalid } = useValidation(userValidations);
  const [userForm, setUserForm] = useState(initialState);
  const [prevUser, setPrevUser] = useState<UserData>();
  const [loading, setLoading] = useState<boolean>(false);
  const [isUsernameUnique, setIsUsernameUnique] = useState(true);
  const [file, setFile] = useState<File>();
  const twitterBtnLabel = userForm.twitter ? 'Disconnect X' : 'Connect X';
  const discordBtnLabel = userForm.discord ? 'Disconnect Discord' : 'Connect Discord';

  errorSocialConnect =
    location.search.match('fail') && location.search.match('in%20use')
      ? 'Account in use by another user'
      : errorSocialConnect;
  errorSocialConnect =
    location.search.match('fail') && location.search.match('not%20succeed')
      ? 'Connection canceled'
      : errorSocialConnect;

  const [connectTwitter, { loading: loadingTwitter }] = useReneMutation(CONNECT_TWITTER_MUTATION, {
    onCompleted: (data: { ConnectTwitterAccount: { authUrl: string } }) => {
      if (data?.ConnectTwitterAccount?.authUrl) {
        window.location.href = data.ConnectTwitterAccount.authUrl;
      }
    },
    variables: {
      redirectUrl: `${window.location.origin}/settings/profile`,
    },
  });
  const [disconnectTwitter, { loading: loadingTwitterDisconnect }] = useReneMutation(DISCONNECT_TWITTER_MUTATION, {
    onCompleted: (data: { DisconnectTwitterAccount: boolean }) => {
      if (data?.DisconnectTwitterAccount) {
        refetch();
      }
    },
  });
  const [connectDiscord, { loading: loadingDiscord }] = useReneMutation(CONNECT_DISCORD_MUTATION, {
    onCompleted: (data: { ConnectDiscordAccount: { authUrl: string } }) => {
      if (data?.ConnectDiscordAccount?.authUrl) {
        window.location.href = data.ConnectDiscordAccount.authUrl;
      }
    },
    variables: {
      redirectUrl: `${window.location.origin}/settings/profile`,
    },
  });

  const [disconnectDiscord, { loading: loadingDiscordDisconnect }] = useReneMutation(DISCONNECT_DISCORD_MUTATION, {
    onCompleted: (data: { DisconnectDiscordAccount: boolean }) => {
      if (data?.DisconnectDiscordAccount) {
        refetch();
      }
    },
  });

  const [updateUser] = useReneMutation(UPDATE_USER_MUTATION, {
    onCompleted(data: { UpdateUser: UserData }) {
      if (file) {
        uploadFile(data.UpdateUser.image.uploadUrl, file).finally(() => {
          refetch();
          setLoading(false);
        });
      } else {
        refetch();
        setLoading(false);
      }
    },
  });

  const [updateUsername] = useReneMutation(UPDATE_USERNAME);

  const checkUsername = async (username: string) => {
    const url = process.env.REACT_APP_RENE_API_URI as string;
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: `
        query CheckUsername($username: String!) {
          CheckUsername(input: { username: $username })
        }
      `,
        variables: { username },
      }),
    });

    const result = await response.json();

    if (response.ok) {
      return setIsUsernameUnique(result.data.CheckUsername);
    } else {
      throw new Error(result.errors ? result.errors[0].message : 'Unknown error');
    }
  };

  if (user && user !== prevUser) {
    if (user) {
      setUserForm({
        firstName: user?.data.firstName,
        lastName: user?.data.lastName,
        email: user?.email,
        username: user?.username || '',
        twitter: user?.externalAccounts?.twitterId || '',
        discord: user?.externalAccounts?.discordId || '',
        image: user?.image?.url || '',
      });
      setPrevUser(user);
    }
  }

  useEffect(() => {
    if (userForm.username && userForm.username !== prevUser?.username)
      debounce(() => checkUsername(userForm.username), 1000)();
  }, [prevUser?.username, userForm.username]);

  const handleChangeForm = (e: Event['Input']) => {
    setUserForm((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleTwitterConnect = () => {
    if (userForm.twitter) {
      disconnectTwitter();
    } else {
      connectTwitter();
    }
  };

  const handleDiscordConnect = () => {
    if (userForm.discord) {
      disconnectDiscord();
    } else {
      connectDiscord();
    }
  };

  const handleUpdateUser = () => {
    if (isFormInvalid(userForm)) return;
    setLoading(true);
    let variables: {
      firstName: string;
      lastName: string;
      discordId?: string;
      image?: {
        extension: string;
        fileId?: string;
      };
    } = {
      firstName: userForm.firstName,
      lastName: userForm.lastName,
      discordId: userForm.discord,
    };

    variables = file ? { ...variables, image: { extension: file?.type.split('/')[1] } } : variables;

    if (userForm.username !== prevUser?.username) {
      updateUsername({
        variables: {
          username: userForm.username,
        },
      });
    }
    updateUser({
      variables,
    });
  };

  return (
    <div className="profile-settings">
      <div className="profile-settings__title">
        <button onClick={() => setOpenMobileMenu(true)}>
          <Icon name="hamburger" />
        </button>
        <h1>Profile</h1>
      </div>
      <div className="profile-settings__info">
        <div className="profile-settings__info_avatar">
          <EditableImage imageUrl={user?.image?.url} alt="profile" setFile={setFile} />
          <div>
            <p>
              {user?.data.firstName} {user?.data.lastName}
            </p>
          </div>
        </div>
        <Input
          label="First name"
          name="firstName"
          type="text"
          placeholder="Enter first name"
          handleInput={handleChangeForm}
          value={userForm.firstName}
          errorMessage={errors?.firstName}
        />
        <Input
          label="Last name"
          name="lastName"
          type="text"
          placeholder="Enter last name"
          handleInput={handleChangeForm}
          value={userForm.lastName}
          errorMessage={errors?.lastName}
        />
        <Input
          label="Username *"
          name="username"
          placeholder="Enter your username"
          handleInput={handleChangeForm}
          value={userForm.username}
          errorMessage={errors?.username || (isUsernameUnique === false && 'Username taken')}
        />
        <Input
          label="Email"
          name="email"
          type="email"
          placeholder="Enter your email"
          handleInput={handleChangeForm}
          value={userForm.email}
          disabled
        />
        <div className="profile-settings__info_twitter">
          <Input label="X" name="twitter" handleInput={handleChangeForm} value={userForm.twitter} disabled />
          <button className="primary-btn" onClick={handleTwitterConnect}>
            {loadingTwitter || loadingTwitterDisconnect ? <Spinner size="sm" /> : twitterBtnLabel}
          </button>
        </div>
        <div className="profile-settings__info_discord">
          <Input
            label="Discord"
            name="discord"
            type="text"
            handleInput={handleChangeForm}
            value={userForm.discord}
            disabled
          />
          <button className="primary-btn" onClick={handleDiscordConnect}>
            {loadingDiscord || loadingDiscordDisconnect ? <Spinner size="sm" /> : discordBtnLabel}
          </button>
        </div>
        <button className="primary-btn center-btn" onClick={handleUpdateUser}>
          {loading ? <Spinner size="sm" /> : 'Update Profile'}
        </button>
        <p className="profile-settings__failed-connection">{errorSocialConnect || ''}</p>
      </div>
      <ChangePassword />
    </div>
  );
};

export default ProfileSettings;
