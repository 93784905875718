import { FC } from 'react';
import Icon from '../Icon/Icon';

import './small-error-msg.scss';

interface SmallErrorMsgProps {
  message: string | undefined;
  className?: string;
}

const SmallErrorMsg: FC<SmallErrorMsgProps> = ({ message, className = '' }) => {
  if (!message) return <div className="small-error-msg" />;
  return (
    <div className={`small-error-msg ${className}`}>
      <Icon name="alert" />
      <p>{message}</p>
    </div>
  );
};

export default SmallErrorMsg;
