import { ReactNode } from 'react';
import { TransactionStatus } from '../../global/consts';

import './status-tag.scss';

type StatusTagProps = {
  status: TransactionStatus;
  children: ReactNode;
};

const StatusTag = ({ status, children }: StatusTagProps) => <div className={`status-tag_${status}`}>{children}</div>;

export default StatusTag;
