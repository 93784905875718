import { useState } from 'react';
import { CampaignFormData } from '../../create-campaign-modal';
import { CreateOfferModal } from '../../../create-offer-modal/create-offer-modal';
import Modal from '../../../modal';
import Select from '../../../../select/select';
import Toggle from '../../../../toggle/toggle';
import Checkbox from '../../../../checkbox/checkbox';
import RadioButtons from '../../../../radio/radio-buttons';
import PreviewOfferModal from '../../../preview-offer-modal/preview-offer-modal';

import './ads-placement.scss';

interface AdsPlacementProps {
  data: CampaignFormData['adsPlacement'];
  updateFormData: (step: keyof CampaignFormData, data: Partial<CampaignFormData[keyof CampaignFormData]>) => void;
}

const AdsPlacement: React.FC<AdsPlacementProps> = ({ data, updateFormData }) => {
  const [selectedFilter, setSelectedFilter] = useState<'included' | 'excluded'>('included');
  const [previewOfferId, setPreviewOfferId] = useState('');
  const [editOfferId, setEditOfferId] = useState('');

  return (
    <div className="ads-placement">
      <h2>Ads placement</h2>
      <h4>Select the games and audience by choosing game titles and relevant IAB categories.</h4>
      <div className="ads-placement__settings">
        <h3>Campaign settings</h3>
        <div className="ads-placement__settings_iab">
          <Select label="IAB categories" value="" options={[]} changeHandler={() => {}} newStyle />
          <div className="ads-placement__settings_iab_filters">
            <label>Included</label>
            <Toggle
              id="included"
              name="included"
              checked={selectedFilter === 'excluded'}
              setValue={() => setSelectedFilter('excluded')}
            />
            <label>Excluded</label>
          </div>
        </div>
        <div className="ads-placement__settings_genres">
          <RadioButtons
            newStyle
            label="Genres"
            options={[
              { label: 'All games', value: 'all' },
              { label: 'Specific genres and titles', value: 'specific' },
            ]}
            selectedValue="all"
            setSelectedValue={() => {}}
          />
          <Select label="Genres" value="" options={[]} changeHandler={() => {}} newStyle />
          <Select label="Titles" value="" options={[]} changeHandler={() => {}} newStyle />
        </div>
      </div>
      <div className="ads-placement__specific">
        <h3>Ad-specific settings</h3>
        {new Array(10).fill(10).map((_, index) => (
          <div className="ads-placement__specific_content">
            <div className="ads-placement__specific_content_left">
              <img src="" alt="" />
              <p>Sprite</p>
              <p>Branded Object</p>
            </div>
            <div className="ads-placement__specific_content_right">
              <Select label="Genres" value="" options={[]} changeHandler={() => {}} newStyle />
              <div className="ads-placement__specific_content_right_iab">
                <Select label="IAB categories" value="" options={[]} changeHandler={() => {}} newStyle />
                <div className="ads-placement__specific_content_right_iab_filters">
                  <button
                    onClick={() => setSelectedFilter('included')}
                    className={selectedFilter === 'included' ? 'active' : ''}
                  >
                    Included
                  </button>
                  <button
                    onClick={() => setSelectedFilter('excluded')}
                    className={selectedFilter === 'excluded' ? 'active' : ''}
                  >
                    Excluded
                  </button>
                </div>
              </div>
              <div className="ads-placement__specific_content_right_offer">
                <Checkbox newStyle value={false} setValue={() => {}}>
                  On-interaction offer
                </Checkbox>
                <div className="ads-placement__specific_content_right_offer_buttons">
                  <button className="btn-neutral-ghost" onClick={() => setPreviewOfferId('dasdas')}>
                    Preview offer
                  </button>
                  <button className="btn-neutral-solid" onClick={() => setEditOfferId('dasdas')}>
                    Edit
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <Modal isOpen={!!previewOfferId}>
        <PreviewOfferModal setIsModalOpen={setPreviewOfferId} />
      </Modal>
      <Modal isOpen={!!editOfferId}>
        <CreateOfferModal setIsModalOpen={setEditOfferId} />
      </Modal>
    </div>
  );
};

export default AdsPlacement;
