import Icon from '../Icon/Icon';
import './files-table.scss';

const FilesTable = ({ files }: { files: any }) => {
  const handleDownload = async (fileUrl: string, fileName: string) => {
    try {
      const response = await fetch(fileUrl);
      const blob = await response.blob();
      // Create a download link using the blob
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);
      link.href = url;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Clean up the URL object
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading the file:', error);
    }
  };

  return (
    <div className="files_table">
      <table>
        <thead>
          <tr>
            <th>File name</th>
            <th>Download</th>
          </tr>
        </thead>
        <tbody>
          {files.map((file: any) => (
            <tr key={file.uploadUrl}>
              <td>{file.name}</td>
              <td>
                <button onClick={() => handleDownload(file.url, file.name)} className="download_button">
                  <Icon name="download" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default FilesTable;
