import { Route, Routes, Navigate } from 'react-router';
import { useProdEffect } from './hooks/useProdEffect';
import TagManager from 'react-gtm-module';
import { LiveChatWidget } from '@livechat/widget-react';
import {
  GAMES,
  HELP,
  HOME,
  LOGIN,
  ORG,
  REGISTER,
  SETTINGS,
  VERIFY,
  FORGOT_PASSWORD,
  ORG_MANAGEMENT,
  PASSWORD,
  FIRST_TIME_USER,
  CREATE_PASSWORD,
  ADMIN,
} from './global/routes';
import {
  OrganizationViewPage,
  GamerViewPage,
  HelpPage,
  ProfileSettingsPage,
  LoginPage,
  PasswordPage,
  CreatePasswordPage,
  RegistrationPage,
  VerifyUserPage,
  ForgotPasswordPage,
  ManageOrganizationsPage,
  FirstTimeUserPage,
} from './pages';
import Header from './components/header/header';
import Portal from './components/portal/portal';
import AuthWrapper from './components/auth-wrapper/auth-wrapper';
import ErrorMessage from './components/messages/error-message/error-message';
import UpdateMessage from './components/messages/update-message/update-message';
import RegistrationGEPLPage from './pages/shared/registration-gepl-page/registration-gepl-page';
import AdminDashboard from './pages/admin/admin-dashboard/admin-dashboard';

const GTM_ID: string = process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID as string;
const LIVECHAT_LICENSE: string = process.env.REACT_APP_LIVECHAT_LICENSE as string;

function App() {
  useProdEffect(() => {
    TagManager.initialize({ gtmId: GTM_ID });
  }, []);

  return (
    <>
      <Routes>
        <Route path={LOGIN} element={<LoginPage />} />
        <Route path={PASSWORD} element={<PasswordPage />} />
        <Route path={`signup/gepl`} element={<RegistrationGEPLPage />} />
        <Route path={`${REGISTER}/*`} element={<RegistrationPage />} />
        <Route path={FORGOT_PASSWORD} element={<ForgotPasswordPage />} />
        <Route path={CREATE_PASSWORD} element={<CreatePasswordPage />} />
        <Route path={HOME} element={<AuthWrapper redirectTo={LOGIN} element={<Header />} />}>
          <Route path={`${ORG}/*`} element={<OrganizationViewPage />} />
          <Route path={`${GAMES}/*`} element={<GamerViewPage />} />
          <Route path={`${ADMIN}/*`} element={<AdminDashboard />} />
          <Route path={`${HELP}`} element={<HelpPage />} />
          <Route path={`${SETTINGS}/:menuItem/*`} element={<ProfileSettingsPage />} />
          <Route path={`${ORG_MANAGEMENT}`} element={<ManageOrganizationsPage />} />
        </Route>
        <Route path={VERIFY} element={<AuthWrapper redirectTo={LOGIN} />}>
          <Route index element={<VerifyUserPage />} />
        </Route>
        <Route path={FIRST_TIME_USER} element={<AuthWrapper redirectTo={LOGIN} />}>
          <Route index element={<FirstTimeUserPage />} />
        </Route>
        <Route path={HOME} element={<AuthWrapper redirectTo={LOGIN} />}>
          <Route index element={<Portal />} />
        </Route>
        <Route path="*" element={<Navigate to={HOME} />} />
      </Routes>
      <LiveChatWidget license={LIVECHAT_LICENSE} visibility="minimized" />
      <ErrorMessage />
      <UpdateMessage />
    </>
  );
}

export default App;
