import { useState } from 'react';
import { Dispatcher } from '../../../global/interfaces';
import Icon from '../../Icon/Icon';
import Input from '../../input/input';
import RadioButtons from '../../radio/radio-buttons';
import Select from '../../select/select';
import FileUpload from '../../file-upload/file-upload';
import InlineFileUpload from '../../inline-file-upload/inline-file-upload';

import './create-ad-modal.scss';

const ImageVideoAds = () => {
  const [filesType, setFilesType] = useState<'single-ad-with-variants' | 'multiple-ads'>('single-ad-with-variants');
  const [files, setFiles] = useState<File[]>([]);
  console.log(files);
  return (
    <div className="image-video-ads">
      <FileUpload
        label="Name"
        setFiles={(files: File[]) => setFiles(files)}
        multiple
        newStyle
        description="Drag and drop files or click here to select (jpg, png, <5mb)"
      />
      <div>
        <RadioButtons
          newStyle
          label="Multiple files uploaded - what are they?"
          options={[
            { label: 'A single ad with variants', value: 'single-ad-with-variants' },
            { label: 'Multiple ads', value: 'multiple-ads' },
          ]}
          selectedValue={filesType}
          setSelectedValue={setFilesType}
        />
        <div className="image-video-ads__files">
          {files &&
            Object.values(files).map((file, i) => (
              <div key={i}>
                <div>
                  <Icon name="file" />
                  <p>{file.name}</p>
                </div>
                <p>{(file.size / (1024 * 1024)).toFixed(2)} Mb</p>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

const CreateAdModal = ({ setIsModalOpen }: { setIsModalOpen: Dispatcher<boolean> }) => {
  const [adType, setAdType] = useState<'branded-object' | 'image-video'>('branded-object');

  const [selectedFiles, setSelectedFiles] = useState<{
    adObject: File | null;
    texture: File | null;
    preview: File | null;
  } | null>(null);

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleFileSelect = (file: File, type: 'adObject' | 'texture' | 'preview') => {
    setSelectedFiles((prev) => ({
      adObject: prev?.adObject ?? null,
      texture: prev?.texture ?? null,
      preview: prev?.preview ?? null,
      [type]: file,
    }));
  };

  console.log(selectedFiles);

  return (
    <div className="create-ad-modal">
      <div className="create-ad-modal__heading">
        <h1>Create Ad</h1>
        <button type="button" onClick={handleModalClose}>
          <Icon name="close" size={24} />
        </button>
      </div>
      <div className="create-ad-modal__ad-type">
        <RadioButtons
          newStyle
          label="Ad type"
          options={[
            { label: 'Branded Object', value: 'branded-object' },
            { label: 'Image/Video', value: 'image-video' },
          ]}
          selectedValue={adType}
          setSelectedValue={setAdType}
        />
      </div>
      <div className="create-ad-modal__content">
        {adType === 'branded-object' ? (
          <>
            <Input newStyle label="Name" value="" placeholder="Enter Ad name" handleInput={() => {}} />
            <Select
              newStyle
              label="Object type"
              value={''}
              options={['3D object']}
              changeHandler={(e) => {}}
              errorMsg=""
              showError={true}
            />
            <InlineFileUpload
              onFileSelect={(file) => handleFileSelect(file, 'adObject')}
              accept=".obj"
              label="Object file (.obj)"
            />
            <InlineFileUpload
              onFileSelect={(file) => handleFileSelect(file, 'texture')}
              accept=".png"
              label="Texture file (.png)"
            />
            <InlineFileUpload
              onFileSelect={(file) => handleFileSelect(file, 'preview')}
              accept=".jpg, .jpeg"
              label="Preview file (.jpg, .jpeg)"
            />
          </>
        ) : (
          <ImageVideoAds />
        )}
      </div>
      <div className="create-ad-modal__footer">
        <button type="button" className="btn-neutral-ghost" onClick={handleModalClose}>
          Cancel
        </button>
        <button type="button" className="btn-primary-solid" onClick={handleModalClose}>
          Create Ad
        </button>
      </div>
    </div>
  );
};

export default CreateAdModal;
