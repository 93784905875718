import LoadingFallback from '../../../components/loading-fallback/loading-fallback';
import OfferList from '../../../components/offers/offer-list/offer-list';
import useOffers from '../hooks/useOffers';
import './gamer-offers-page.scss';

const GamerOffersPage = () => {
  const { offers, loading } = useOffers();

  return (
    <div className="gamer-offers-page">
      <div className="gamer-offers-page_header">
        <h3>Offers</h3>
        <span>{offers?.length}</span>
      </div>
      {loading ? <LoadingFallback /> : <OfferList offers={offers} />}
    </div>
  );
};

export default GamerOffersPage;
