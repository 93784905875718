import { DragEvent } from 'react';
import { Link } from 'react-router-dom';
import avatar from '../../global/images/avatar.webp';
import './small-dashboard-bar.scss';

interface Props {
  id: string;
  children?: React.ReactElement | React.ReactElement[] | never[];
  linkTo?: string;
  name: string;
  imageUrl?: string;
  showImage?: boolean;
  description?: string;
  videoUrl?: string;
  draggable?: boolean;
  onDragStart?: (e: DragEvent<HTMLDivElement>) => void;
  onDragOver?: (e: DragEvent<HTMLDivElement>) => void;
  onDrop?: (e: DragEvent<HTMLDivElement>) => void;
}
const SmallDashboardBar: React.FC<Props> = ({
  id,
  children,
  linkTo,
  name,
  imageUrl,
  showImage = true,
  description,
  videoUrl,
  draggable,
  onDragStart = () => {},
  onDragOver,
  onDrop = () => {},
}) => {
  const dashboardBarContent = (): React.ReactElement => (
    <div
      id={id}
      className="small-dashboard-bar"
      draggable={draggable}
      onDragStart={onDragStart}
      onDragOver={onDragOver}
      onDrop={onDrop}
    >
      <div className="small-dashboard-bar__info">
        {videoUrl ? (
          <video autoPlay muted>
            <source src={videoUrl} />
          </video>
        ) : (
          showImage && (
            <div>
              <img src={imageUrl || avatar} alt="game poster" />
            </div>
          )
        )}
        <div>
          <h2>{name}</h2>
          <h3>{description}</h3>
        </div>
      </div>
      {children}
    </div>
  );
  return linkTo ? <Link to={linkTo}>{dashboardBarContent()}</Link> : dashboardBarContent();
};

export default SmallDashboardBar;
