import { useState } from 'react';
import { useParams } from 'react-router-dom';
import AsideMenu from '../../../../components/aside-menu/aside-menu';
import { UserRole } from '../../../../global/consts';
import { GET_GAMES_QUERY, GET_USER_QUERY } from '../../../../global/gql/queries';
import { GamesData, MenuItems, UserData } from '../../../../global/interfaces';
import { useReneQuery } from '../../../../hooks/useReneQuery';
import { isRoleAllowed } from '../../../../utils';
import ApiCredentials from './api-credentials/api-credentials';
import General from './general/general';

import './organization-game-settings.scss';

const menuItems: MenuItems = {
  general: { name: 'general', icon: 'settings', label: 'General' },
  apiCredentials: { name: 'apiCredentials', icon: 'key', label: 'Api Credentials' },
};

const OrganizationGameSettings = () => {
  const params = useParams();
  const [selectedMenuItem, setSelectedMenuItem] = useState<string>('general');
  const { data, refetch } = useReneQuery<{ Games: GamesData } | undefined>(GET_GAMES_QUERY, {
    variables: { gameId: params.gameId },
  });

  const { data: user } = useReneQuery<{ User: UserData }>(GET_USER_QUERY);

  const isUserAllowedToUpsert = isRoleAllowed(user?.User.role as UserRole, [UserRole.DEVELOPER]);
  const isAgreedToLicense = user?.User.isAgreedToLicense as boolean;

  const handleTabSelect = (value: string) => {
    setSelectedMenuItem(menuItems[value].name);
  };

  const renderSelectedMenuItem = (selectedMenuItem: string) => {
    switch (selectedMenuItem) {
      case 'general':
        return (
          <General isUserAllowedToUpsert={isUserAllowedToUpsert} game={data?.Games.items[0]} refetchGames={refetch} />
        );
      case 'apiCredentials':
        return (
          <ApiCredentials
            isAgreedToLicense={isAgreedToLicense}
            isUserAllowedToUpsert={isUserAllowedToUpsert}
            game={data?.Games.items[0]}
          />
        );
      default:
        break;
    }
  };

  return (
    <div className="organization-game-settings">
      <div className="organization-game-settings__aside">
        <AsideMenu items={menuItems} selectedItem={selectedMenuItem} handleItemSelect={handleTabSelect} />
      </div>
      <div className="organization-game-settings__main">{renderSelectedMenuItem(selectedMenuItem)}</div>
    </div>
  );
};

export default OrganizationGameSettings;
