import { useState } from 'react';
import { useReneQuery } from '../../../../../hooks';
import { CampaignFormData } from '../../create-campaign-modal';
import { GET_ORG_QUERY } from '../../../../../global/gql/queries';
import Input from '../../../../input/input';
import Modal from '../../../modal';
import TopUpCreditModal from '../../../top-up-credit-modal/top-up-credit-modal';
import { OrganizationData } from '../../../../../global/interfaces';
import './budget.scss';

interface BudgetProps {
  data: CampaignFormData['budget'];
  updateFormData: (step: keyof CampaignFormData, data: Partial<CampaignFormData[keyof CampaignFormData]>) => void;
}

const Budget: React.FC<BudgetProps> = ({ data, updateFormData }) => {
  const [isTopupOpen, setIsTopupOpen] = useState(false);

  const { data: orgData, refetch: refetchOrg } = useReneQuery<{ Organization: OrganizationData }>(GET_ORG_QUERY);

  const updateBudget = (data: Partial<CampaignFormData['budget']>) => {
    updateFormData('budget', data);
  };
  return (
    <div className="budget">
      <h2>Budget & Scheduling</h2>
      <h3>Set Your Campaign Budget and Define Ad Scheduling for Optimal Reach.</h3>
      <div className="budget__date">
        <h4>Campaign settings</h4>
        <div className="budget__date_calendar">
          <Input
            label="Start Date"
            type="date"
            name="startDate"
            placeholder="Select start date"
            value={data.startDate}
            handleInput={(e) =>
              updateBudget({
                startDate: e.target.value,
              })
            }
            errorMessage=""
            disabled={false}
            newStyle
          />
          <Input
            label="End Date"
            type="date"
            name="endDate"
            placeholder="Select end date"
            value={data.endDate}
            handleInput={(e) =>
              updateBudget({
                endDate: e.target.value,
              })
            }
            errorMessage=""
            disabled={false}
            newStyle
          />
        </div>
      </div>
      <div className="budget__stats">
        <h4>Budget</h4>
        <h5>
          When you create a campaign, we reserve the amount from your credit balance. It is only charged at the end of
          the campaign. If you delete campaign, the amount is refunded to your credit balance.{' '}
        </h5>
        <div className="budget__stats_table">
          <table>
            <thead>
              <tr>
                <th>Country/Market</th>
                <th>Ad type</th>
                <th>Impression price</th>
                <th>Quantity</th>
                <th>Subtotal</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>United States</td>
                <td>Display</td>
                <td>$0.50</td>
                <td>1,000</td>
                <td>$500.00</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="budget__total">
        <button className="btn-neutral-solid" onClick={() => setIsTopupOpen(true)}>
          Top-up
        </button>
        <p>Your balance: $1000.00</p>
      </div>
      <Modal isOpen={isTopupOpen}>
        <TopUpCreditModal
          balance={orgData?.Organization.balance?.balance as string}
          setIsModalOpen={setIsTopupOpen}
          redirectPage=""
          refetch={refetchOrg}
        />
      </Modal>
    </div>
  );
};

export default Budget;
