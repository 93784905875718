import { useNavigate } from 'react-router-dom';
import Icon from '../../../components/Icon/Icon';
import LoadingFallback from '../../../components/loading-fallback/loading-fallback';
import RecommendationsGamer from '../../../components/recommendations-gamer/recommendations-gamer';
import { GameStatus } from '../../../global/consts';
import { GET_GAMES_GAMER_QUERY, GET_USER_QUERY } from '../../../global/gql/queries';
import { GamesData, UserData } from '../../../global/interfaces';
import { useReneQuery } from '../../../hooks';
import { formatNumber } from '../../../utils';

import { useState } from 'react';
import { OFFERS } from '../../../global/routes';
import useOffers from '../hooks/useOffers';
import './gamer-dashboard-page.scss';

const GamerDashboardPage = () => {
  const { data: reneGames, loading: gamesLoading } = useReneQuery<{ Games: GamesData }>(GET_GAMES_GAMER_QUERY);
  const { data: user } = useReneQuery<{ User: UserData }>(GET_USER_QUERY);
  const { offers, loading: offersLoading, unviewedCount } = useOffers();
  const mostRecentOffer = offers?.[0]?.adOffer;
  const [hasSeenOffersTooltip, setHasSeenOffersTooltip] = useState<boolean>(
    localStorage.getItem('hasSeenOffersTooltip') === 'true',
  );

  const handleOffersTooltipClose = () => {
    setHasSeenOffersTooltip(true);
    localStorage.setItem('hasSeenOffersTooltip', 'true');
  };

  const navigate = useNavigate();

  return (
    <div className="gamer-dashboard-page">
      {offersLoading ? (
        <LoadingFallback />
      ) : (
        mostRecentOffer && (
          <button
            className={`gamer-dashboard-page__offers${!hasSeenOffersTooltip ? ' cursor-default' : ''}`}
            onClick={() => (hasSeenOffersTooltip ? navigate(OFFERS) : null)}
          >
            <div className="gamer-dashboard-page__offers_envelop">
              <Icon name="mail" size={24} />
              {!!unviewedCount && (
                <div className="gamer-dashboard-page__offers_envelop_counter">
                  <p>{unviewedCount}</p>
                </div>
              )}
            </div>
            <div className="gamer-dashboard-page__offers_text">
              <p>
                {mostRecentOffer.brand.name}: {mostRecentOffer.title}
              </p>
              <p>{mostRecentOffer.message}</p>
            </div>
            {hasSeenOffersTooltip ? null : (
              <>
                <div className="gamer-dashboard-page__offers_tooltip">
                  <p className="gamer-dashboard-page__offers_tooltip_heading">Discover Your Offers!</p>
                  <p className="gamer-dashboard-page__offers_tooltip_text">
                    Stay updated on exclusive offers, discounts, and product updates from your favorite brands. Discover
                    the latest deals and save big - tap now to explore personalized messages from top brands, all in one
                    place!
                  </p>
                  <button
                    className="gamer-dashboard-page__offers_tooltip_close-button"
                    onClick={handleOffersTooltipClose}
                  >
                    <Icon name="close" size={24} />
                  </button>
                </div>
                <div className="gamer-dashboard-page__overlay" />
              </>
            )}
          </button>
        )
      )}
      <div className="gamer-dashboard-page__earnings">
        <p>My earnings</p>
        <div>
          <p>${formatNumber(parseInt((user?.User?.earnings as string) || '0'), 2)}</p>
          <Icon name="wallet" />
        </div>
      </div>
      <RecommendationsGamer />
      <div className="gamer-dashboard-page__games">
        <h2>Games</h2>
        {gamesLoading ? (
          <LoadingFallback />
        ) : (
          reneGames?.Games.items.map((game) => {
            const isGameConnected = game.gameConnect?.status === GameStatus.CONNECT_CONFIRMED;
            return (
              <button
                key={game.gameId}
                className="gamer-dashboard-page__games_game"
                onClick={() => navigate(game.gameId)}
              >
                <img src={game.image.url} alt="game" />
                <div>
                  <h3>{game.name}</h3>
                  <div>
                    <p className={isGameConnected ? 'connected' : ''}>
                      {isGameConnected ? 'Connected' : 'Connect to earn'}
                    </p>
                    <p>{game.category}</p>
                  </div>
                </div>
              </button>
            );
          })
        )}
      </div>
    </div>
  );
};

export default GamerDashboardPage;
