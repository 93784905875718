export const HOME = '/';
export const ADMIN = 'admin';
export const CAMPAIGN = 'campaign';
export const CAMPAIGNS = 'campaigns';
export const COLLECTION = 'collection';
export const COLLECTIONS = 'collections';
export const LOGIN = 'login';
export const PASSWORD = 'password';
export const ORG = 'org';
export const BRANDS = 'brands';
export const GAMES = 'games';
export const OFFERS = 'offers';
export const ASSETS = 'assets';
export const AD_SURFACES = 'ad_surfaces';
export const OWNABLE_ASSETS = 'ownable';
export const BRANDED_ASSETS = 'branded';
export const METADATA = 'metadata';
export const OWNERS = 'owners';
export const PLAYERS = 'players';
export const SETTINGS = 'settings';
export const STUDIO = 'studio';
export const HELP = 'help';
export const FORGOT_PASSWORD = 'forgot_password';
export const CREATE_PASSWORD = 'create_password';
export const ORG_MANAGEMENT = 'management';
export const REGISTER = 'register';
export const VERIFY = 'verify';
export const ACTIVITY = 'activity';
export const ORG_CREDIT = '/org/settings/credit';
export const FIRST_TIME_USER = 'first_time';

//external
export const DOCS_LINK = 'https://docs.reneverse.io';
export const PRIVACY_POLICY = 'https://reneverse.io/privacy-policy';
export const CDN_CHAIN_LOGOS = 'https://cdn.reneverse.io/chain';
