import { useState } from 'react';
import { CampaignsData, OrganizationData, Refetch } from '../../../global/interfaces';
import Icon from '../../Icon/Icon';
import Budget from './steps/budget/budget';
import Review from './steps/review/review';
import SelectAd from './steps/select-ad/select-ad';
import Overview from './steps/overview/overview';
import AdsPlacement from './steps/ads-placement/ads-placement';

import './create-campaign-modal.scss';

export interface CampaignFormData {
  overview: {
    name: string;
    objective: string;
    market: string[];
    ageRange: { min: number; max: number };
    countries: string[];
    gender: string;
  };
  selectedAds: string[];
  adsPlacement: {
    platforms: string[];
    placements: string[];
  };
  budget: {
    amount: number;
    startDate: string;
    endDate: string;
    schedule: Record<string, any>;
  };
}

const steps = [
  {
    title: 'Overview & Target Audience',
    order: 1,
  },
  {
    title: 'Select Ad',
    order: 2,
  },
  {
    title: 'Ads placement',
    order: 3,
  },
  {
    title: 'Budget & Scheduling',
    order: 4,
  },
  {
    title: 'Review & Launch',
    order: 5,
  },
];

const CreateCampaignModal = ({
  refetch,
  setCloseModal,
}: {
  refetch: Refetch<{ Organization: OrganizationData } | { AdCampaigns: CampaignsData } | undefined>;
  setCloseModal: () => void;
}) => {
  const [activeStep, setActiveStep] = useState(1);
  const [formData, setFormData] = useState({
    overview: {
      name: '',
      objective: 'Branded Awareness',
      market: [],
      countries: [],
      ageRange: { min: 18, max: 65 },
      gender: '',
    },
    selectedAds: [],
    adsPlacement: {
      platforms: [],
      placements: [],
    },
    budget: {
      amount: 0,
      startDate: '',
      endDate: '',
      schedule: {},
    },
  });

  const updateFormData = (step: keyof CampaignFormData, data: Partial<CampaignFormData[keyof CampaignFormData]>) => {
    setFormData((prev) => ({
      ...prev,
      [step]: { ...prev[step], ...data },
    }));
  };

  return (
    <div className="create-campaign-modal full-size">
      <div className="create-campaign-modal__heading">
        <div>
          <h1>Create campaign</h1>
        </div>
      </div>
      <aside className="create-campaign-modal__aside">
        {steps.map((step) => (
          <button
            type="button"
            onClick={() => setActiveStep(step.order)}
            className={`create-campaign-modal__aside-item ${
              activeStep === step.order ? 'create-campaign-modal__aside-item--active' : ''
            } ${activeStep > step.order ? 'create-campaign-modal__aside-item--completed' : ''}`}
          >
            <div>{activeStep <= step.order ? <p>{step.order}</p> : <Icon name="checked" size={24} />}</div>
            <p>{step.title}</p>
          </button>
        ))}
      </aside>
      <div className="create-campaign-modal__main">
        {activeStep === 1 && <Overview data={formData.overview} updateFormData={updateFormData} />}
        {activeStep === 2 && <SelectAd data={formData.selectedAds} updateFormData={updateFormData} />}
        {activeStep === 3 && <AdsPlacement data={formData.adsPlacement} updateFormData={updateFormData} />}
        {activeStep === 4 && <Budget data={formData.budget} updateFormData={updateFormData} />}
        {activeStep === 5 && <Review formData={formData} />}
        <div className="create-campaign-modal__main_footer">
          <div>
            <button className="btn-neutral-ghost" onClick={() => setCloseModal()}>
              Cancel
            </button>
          </div>
          <div>
            <button className="btn-primary-ghost">Finish later</button>
            <button className="btn-primary-solid" onClick={() => setActiveStep(activeStep + 1)}>
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateCampaignModal;
