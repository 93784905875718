import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Input from '../../../components/input/input';
import LoadingFallback from '../../../components/loading-fallback/loading-fallback';
import Spinner from '../../../components/spinner/spinner';
import { useUpdatePopUpContext } from '../../../context/update-message-context';
import { UserRole } from '../../../global/consts';
import { ADD_CREDITS_TO_ORG, UPSERT_PROMOTION } from '../../../global/gql/mutations';
import { GET_USER_QUERY } from '../../../global/gql/queries';
import { PromotionData, UserData } from '../../../global/interfaces';
import { useFileUpload, useReneMutation, useReneQuery, useValidation } from '../../../hooks';

import FileUpload from '../../../components/file-upload/file-upload';
import './admin-dashboard.scss';
import { promotionFormValidations } from './validations';

type AdminDashboardTab = 'credit' | 'promotions';

const AdminDashboard = () => {
  const navigate = useNavigate();
  const { showUpdatePopUpMessage } = useUpdatePopUpContext();

  const uploadFile = useFileUpload();

  const [selectedTab, setSelectedTab] = useState<AdminDashboardTab>('credit');

  const [credits, setCredits] = useState({
    amount: '',
    account: '',
  });

  const { data: user } = useReneQuery<{ User: UserData } | undefined>(GET_USER_QUERY);

  const [addCredits, { loading: loadingCredits }] = useReneMutation(ADD_CREDITS_TO_ORG, {
    onCompleted: () => {
      setCredits({
        amount: '',
        account: '',
      });
      showUpdatePopUpMessage('Credits allocated');
    },
  });

  const [promotionForm, setPromotionForm] = useState<{
    buttonText: string;
    buttonUrl: string;
    message: string;
    title: string;
    promotionImageFile: File | null;
  }>({
    buttonText: '',
    buttonUrl: '',
    message: '',
    title: '',
    promotionImageFile: null,
  });

  const handleFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setPromotionForm((prev) => ({ ...prev, [name]: value }));
  };

  const setFile = (files: File[]) => {
    setPromotionForm((prev) => ({ ...prev, promotionImageFile: files?.[0] }));
  };

  const [addPromotion, { loading: loadingPromotion }] = useReneMutation(UPSERT_PROMOTION, {
    onCompleted: async (data: { UpsertPromotion: PromotionData }) => {
      try {
        if (promotionForm?.promotionImageFile) {
          uploadFile(data.UpsertPromotion.promotionImage.uploadUrl, promotionForm.promotionImageFile).finally(() => {
            setPromotionForm({
              buttonText: '',
              buttonUrl: '',
              message: '',
              title: '',
              promotionImageFile: null,
            });
            setFile([]);
          });
        }
      } catch (error) {
        console.error(error);
      } finally {
        showUpdatePopUpMessage('Promotion added');
      }
    },
  });

  const { errors } = useValidation(promotionFormValidations);

  const isReneUser = user?.User?.email?.match('@reneverse.io');

  if (user && isReneUser && user.User.role !== UserRole.ADMIN && user.User.role !== UserRole.OWNER) {
    navigate(-1);
    return null;
  }

  const handleAddCredits = () => {
    addCredits({
      variables: {
        orgId: credits.account,
        allocatedCredits: credits.amount,
      },
    });
  };

  const handleAddPromotion = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const input = {
      title: promotionForm.title,
      message: promotionForm.message,
      buttonText: promotionForm.buttonText,
      buttonUrl: promotionForm.buttonUrl,
      promotionImage: { extension: promotionForm?.promotionImageFile?.type.split('/')[1] },
    };
    addPromotion({
      variables: {
        input,
      },
    });
  };

  if (user)
    return (
      <div className="admin">
        <div className="admin-dashboard">
          <div className="admin-dashboard__tabs">
            <button
              onClick={() => setSelectedTab('credit')}
              className={`admin-dashboard__tab ${selectedTab === 'credit' ? 'active' : ''}`}
              type="button"
            >
              Credits
            </button>
            <button
              onClick={() => setSelectedTab('promotions')}
              className={`admin-dashboard__tab ${selectedTab === 'promotions' ? 'active' : ''}`}
              type="button"
            >
              Promotions
            </button>
          </div>
          {selectedTab === 'credit' && (
            <div className="admin-dashboard__credit">
              <h2>Select account to add credits</h2>
              <div>
                <Input
                  className="admin-dashboard__credit_org"
                  value={credits.account}
                  handleInput={(e) => setCredits((prev) => ({ ...prev, account: e.target.value }))}
                  placeholder="Org Id"
                />
                <Input
                  type="number"
                  value={credits.amount}
                  handleInput={(e) => setCredits((prev) => ({ ...prev, amount: e.target.value }))}
                  placeholder="Add credits"
                />
                <button
                  onClick={handleAddCredits}
                  className="primary-btn"
                  disabled={!credits.account || !credits.amount || loadingCredits}
                >
                  {loadingCredits ? <Spinner size="sm" /> : 'Add'}
                </button>
              </div>
            </div>
          )}
          {selectedTab === 'promotions' && (
            <div className="admin-dashboard__promotions">
              <h2>Add Promotion</h2>
              <form onSubmit={handleAddPromotion}>
                <Input
                  name="title"
                  value={promotionForm.title}
                  handleInput={handleFormChange}
                  placeholder="Title"
                  errorMessage={errors?.title}
                />
                <Input
                  name="message"
                  value={promotionForm.message}
                  handleInput={handleFormChange}
                  placeholder="Message"
                  errorMessage={errors?.message}
                />
                <Input
                  name="buttonText"
                  value={promotionForm.buttonText}
                  handleInput={handleFormChange}
                  placeholder="Button Text"
                  errorMessage={errors?.buttonText}
                />
                <Input
                  name="buttonUrl"
                  value={promotionForm.buttonUrl}
                  handleInput={handleFormChange}
                  placeholder="Button Url"
                  errorMessage={errors?.buttonUrl}
                />
                <FileUpload label="Promotion Image" setFiles={setFile} description="Upload Promotion Picture" />
                <button
                  type="submit"
                  className="primary-btn"
                  disabled={loadingPromotion || Object.keys(errors ?? {}).length > 0}
                >
                  {loadingPromotion ? <Spinner size="sm" /> : 'Submit'}
                </button>
              </form>
            </div>
          )}
        </div>
      </div>
    );

  return <LoadingFallback />;
};

export default AdminDashboard;
