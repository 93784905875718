import { useApolloClient, useMutation } from '@apollo/client';
import { format } from 'date-fns';
import { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Icon from '../../../../components/Icon/Icon';
import LoadingFallback from '../../../../components/loading-fallback/loading-fallback';
import { MARK_AD_OFFER_AS_VIEWED } from '../../../../global/gql/mutations';
import { GAMES, OFFERS } from '../../../../global/routes';
import useOffers from '../../hooks/useOffers';
import './gamer-offer-details-page.scss';

const GamerOfferDetailsPage = () => {
  const { offerId } = useParams<{ offerId: string }>();
  const navigate = useNavigate();

  const { offers, loading } = useOffers(offerId ?? '');
  const [markOfferAsViewed, { loading: markingAsViewed, called }] = useMutation(MARK_AD_OFFER_AS_VIEWED);
  const queryClient = useApolloClient();

  const offerObject = offers?.[0];
  const { adOffer: offer, isViewed } = offerObject || {};

  if (!offerId || (!offer && !loading)) {
    navigate(`/${GAMES}/${OFFERS}`);
  }

  useEffect(() => {
    if (offer && offerId && !markingAsViewed && !loading && !called && !isViewed) {
      markOfferAsViewed({
        variables: { input: { adOfferId: offerId } },
        onCompleted: async () => {
          await queryClient.refetchQueries({ include: ['UserAdOffers'] });
        },
      });
    }
  }, [offer, offerId, markingAsViewed, loading, called, isViewed, markOfferAsViewed, queryClient]);

  return (
    <div className="gamer-offer-page">
      {loading ? (
        <LoadingFallback />
      ) : (
        <>
          <Link to={`/${GAMES}/${OFFERS}`}>
            <Icon name="chevron-left" size={16} />
            <h4>{offer?.title}</h4>
          </Link>
          <div className="gamer-offer-page_brand">
            <img src={offer?.brand?.image?.url} alt={offer?.brand?.name} />
            <h5>{offer?.brand?.name}</h5>
            <p>{format(new Date(offer.createdAt), 'MMM d, h:mm a')}</p>
          </div>
          <div className="gamer-offer-page_content">
            <p>{offer?.message}</p>
            <img src={offer?.adOfferImage?.uploadUrl} alt={offer?.adOfferImage?.name} />
            {offer?.buttonText && offer?.buttonUrl && (
              <a className="primary-btn" href={offer?.buttonUrl} target="_blank" rel="noreferrer">
                {offer?.buttonText}
              </a>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default GamerOfferDetailsPage;
