import { gql } from '@apollo/client';

export const CHAIN_FIELDS = gql`
  fragment ChainFields on Chain {
    name
    chainSymbol
    mainnet {
      chainId
      name
      isActive
    }
    testnet {
      chainId
      name
    }
  }
`;

export const USER_FIELDS = gql`
  fragment UserFields on User {
    email
    username
    data {
      firstName
      lastName
    }
    isAgreedToLicense
    externalAccounts {
      discordId
      twitterId
    }
    image {
      url
      uploadUrl
      fileId
    }
    isActive
    orgId
    role
    scopes
    stats {
      assets
      games
      value
    }
    userId
    verified {
      isEmail
    }
    walletAddress
    notifications {
      message
      meta {
        key
        value
      }
      notificationId
      type
      userId
      isRead
    }
    gettingStartedSteps {
      gettingStartedStepId
      order
      title
      isDone
      type
      url
    }
    balance {
      balance
    }
  }
`;

export const ASSET_FIELDS = gql`
  fragment AssetFields on Asset {
    assetId
    ownableAssetId
    cId
    gameId
    nftId
    walletAddress
    chain {
      ...ChainFields
    }
    metadata {
      animationUrl
      description
      image
      name
      attributes {
        traitType
        value
      }
    }
  }
  ${CHAIN_FIELDS}
`;

export const TRANSACTION_FIELDS = gql`
  fragment TransactionFields on Transaction {
    ownableAssetId
    date
    type
    status
    nftId
    charge {
      currency
      value
    }
    sender {
      name
      imageUrl
      walletAddress
    }
    gasFees {
      chainId
      value
    }
    receiver {
      name
      imageUrl
      walletAddress
    }
    asset {
      assetId
      metadata {
        name
        image
      }
    }
  }
`;

export const OWNABLE_ASSET_FIELDS = gql`
  fragment OwnableAssetFields on OwnableAsset {
    ownableAssetId
    name
    updatedAt
    crossGameState
    parentOwnableAssetId
    image {
      isIpfs
      url
      extension
      uploadUrl
      fileId
    }
    attributes {
      displayType
      traitType
      values
    }
    files {
      animations {
        name
        url
        extension
        isIpfs
        uploadUrl
      }
      images {
        name
        url
        extension
        isIpfs
        uploadUrl
      }
    }
    gameEngineFiles {
      url
      extension
      isIpfs
      uploadUrl
      name
    }
    data {
      description
      price
      supply
    }
    metadataTemplates {
      backgroundColor
      description
      name
    }
    stats {
      assets
    }
  }
`;

export const BRANDED_ASSET_FIELDS = gql`
  fragment BrandedAssetFields on BrandedAsset {
    brand {
      brandId
      description
      image {
        uploadUrl
        url
        extension
      }
      name
      updatedAt
      website
    }
    brandedAssetId
    description
    gameEngineFiles {
      uploadUrl
      url
      name
    }
    name
    updatedAt
    image {
      uploadUrl
      url
      fileId
    }
    stats {
      games
      impressions
    }
  }
`;

export const GAME_FIELDS = gql`
  fragment GameFields on Game {
    isActive
    name
    orgId
    gameId
    updatedAt
    description
    image {
      url
      fileId
    }
    gameConnect {
      status
    }
    gameConnectAuth {
      authorized
    }
    stats {
      mintedAssets
      walletAssets
      players
      value
    }
    adCampaignStats {
      impressions
    }
    players {
      items {
        ...UserFields
      }
    }
    apiCredentials {
      apiKey
      apiSecret
      expiry
      isTestNet
      scopes
    }
    collections {
      items {
        collectionId
        name
        type
        description
        brand {
          name
        }
        image {
          url
        }
        stats {
          assets
          games
          impressions
          interactions
          value
          adCampaigns
        }
      }
    }
    iosData {
      bundleId
      url
    }
    androidData {
      packageName
      url
    }
    webData {
      url
    }
  }
  ${USER_FIELDS}
`;
