import { Suspense, useEffect, useRef, useState } from 'react';
import { CustomFile } from '../../../global/interfaces';
import Icon from '../../Icon/Icon';
import ThreeDViewer from '../../three-d-viewer/three-d-viewer';
import './detailed-resource-view.scss';

const DetailedResourceImage = ({
  url,
  widthPercentage,
  setWidthPercentage,
  dimensions,
  setDimensions,
}: {
  url: string;
  widthPercentage: number;
  setWidthPercentage: (pct: number) => void;
  dimensions: { width: number; height: number };
  setDimensions: (dim: { width: number; height: number }) => void;
}) => {
  const imgRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    if (url) {
      const img = new Image();
      img.src = url;
      img.onload = () => {
        setDimensions({
          width: img.naturalWidth,
          height: img.naturalHeight,
        });
      };
    }
  }, [url, setDimensions]);

  useEffect(() => {
    const imgElement = imgRef.current;
    if (imgElement) {
      const resizeObserver = new ResizeObserver((entries) => {
        for (let entry of entries) {
          const { width } = entry.contentRect;
          if (isNaN(width)) return;
          const newWidth = (width / dimensions.width) * 100;
          const diff = Math.abs(newWidth - widthPercentage);
          if (diff < 1) return;
          setWidthPercentage((width / dimensions.width) * 100);
        }
      });

      resizeObserver.observe(imgElement);

      return () => {
        resizeObserver.unobserve(imgElement);
      };
    }
  }, [imgRef, dimensions.width, setWidthPercentage, widthPercentage]);

  const width = dimensions.width ? (widthPercentage * dimensions.width) / 100 : '100%';

  return (
    <div className="detailed-resource-img">
      <img ref={imgRef} src={url} alt="detailed resource view" width={width} />
      <div>
        <p>{dimensions.width}</p>
        <p>x</p>
        <p>{dimensions.height}px</p>
      </div>
    </div>
  );
};

const DetailedResourceView = ({
  name,
  model,
  image,
  texture,
  closeModal,
}: {
  name: string;
  image: string;
  model?: CustomFile;
  texture?: CustomFile;
  closeModal: () => void;
}) => {
  const [widthPercentage, setWidthPercentage] = useState(0);
  const [dimensions, setDimensions] = useState({
    width: 0,
    height: 0,
  });

  const handleSliderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if ((dimensions.width * Number(event.target.value)) / 100 >= window.innerWidth * 0.9) {
      return;
    }
    setWidthPercentage(Number(event.target.value));
  };

  const renderedWidth = (dimensions.width * widthPercentage) / 100;
  const renderedHeight = (dimensions.height * widthPercentage) / 100;

  const maxWidth = dimensions.width < window.innerWidth / 2 ? '200' : '100';

  return (
    <Suspense>
      <div className="detailed-resource-view">
        <div className="detailed-resource-view__heading">
          <h2>{name}</h2>
          <button type="button" onClick={closeModal}>
            <Icon name="close" size={24} />
          </button>
        </div>
        <div className="detailed-resource-view__main">
          {model && <h3>Preview size 100%</h3>}
          {!model && (
            <>
              <h3>
                <span>Preview size {widthPercentage.toFixed(1)}%</span>{' '}
                <span>
                  {renderedWidth.toFixed(1)} x {renderedHeight.toFixed(1)} px
                </span>
              </h3>
              <input
                type="range"
                min="0"
                max={maxWidth}
                step={0.1}
                value={widthPercentage}
                onChange={handleSliderChange}
                className="detailed-resource-view__slider"
              />
            </>
          )}
          <div className="detailed-resource-view__main_modal">
            {model && texture && <ThreeDViewer objUrl={model.url} textureUrl={texture.url} />}
            {!model && (
              <DetailedResourceImage
                url={image}
                widthPercentage={widthPercentage}
                setWidthPercentage={setWidthPercentage}
                dimensions={dimensions}
                setDimensions={setDimensions}
              />
            )}
          </div>
          {model && <Icon name="rotate" />}
        </div>
      </div>
    </Suspense>
  );
};

export default DetailedResourceView;
