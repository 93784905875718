import { AdOffer } from '../../../global/interfaces';
import OfferListItem from './offer-list-item';
import './offer-list.scss';

type OfferListProps = {
  offers: AdOffer[];
};

const OfferList = ({ offers }: OfferListProps) => {
  return (
    <div className="offer-list">
      {offers.map((offer) => (
        <OfferListItem key={offer?.userAdOfferId} offer={offer} />
      ))}
    </div>
  );
};

export default OfferList;
