import { Canvas, useLoader } from '@react-three/fiber';
import { Stage, PresentationControls } from '@react-three/drei';
import { OBJLoader } from 'three-stdlib';
import { TextureLoader, MeshBasicMaterial } from 'three';
import { Suspense } from 'react';

const ThreeDViewer = ({ objUrl, textureUrl }: { objUrl: string; textureUrl: string }) => {
  const obj = useLoader(OBJLoader, objUrl);
  const texture = useLoader(TextureLoader, textureUrl);
  obj.traverse((child: any) => {
    if (child.isMesh) {
      child.material = new MeshBasicMaterial({ map: texture });
      child.castShadow = false;
      child.receiveShadow = false;
    }
  });
  return (
    <Canvas>
      <Suspense fallback={<span>Loading 3D Model...</span>}>
        <PresentationControls speed={1.5} global zoom={0.5} polar={[-0.1, Math.PI / 4]}>
          <Stage environment={null}>
            <primitive object={obj} />;
          </Stage>
        </PresentationControls>
      </Suspense>
    </Canvas>
  );
};

export default ThreeDViewer;
