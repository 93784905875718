import React, { useState, useRef, useEffect } from 'react';
import './multi-select.scss';
import SmallErrorMsg from '../small-error-msg/small-error-msg';
import Icon from '../Icon/Icon';

interface Option {
  value: string;
  label: string;
}

interface MultiSelectProps {
  options: Option[] | string[];
  selectedValues?: string[] | string;
  onChange: (selected: string[] | string) => void;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
  multiSelect?: boolean;
  label?: string;
  errorMessage?: string;
}

export const MultiSelect: React.FC<MultiSelectProps> = ({
  options,
  selectedValues = [],
  onChange,
  placeholder = 'Select options',
  className = '',
  disabled = false,
  multiSelect = false,
  label,
  errorMessage,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleOptionClick = (value: string) => {
    let updatedSelection: string[] | string;

    if (multiSelect) {
      const currentSelection = Array.isArray(selectedValues) ? selectedValues : [selectedValues];
      updatedSelection = currentSelection.includes(value)
        ? currentSelection.filter((item) => item !== value)
        : [...currentSelection, value];
    } else {
      updatedSelection = Array.isArray(selectedValues) && selectedValues.includes(value) ? '' : value;
      setIsOpen(false);
    }

    onChange(updatedSelection);
  };

  const getSelectedLabels = () => {
    const values = Array.isArray(selectedValues) ? selectedValues : [selectedValues];
    return values
      .map((value) => {
        if (!value) return null;
        if (typeof options[0] === 'string') {
          return value;
        }
        return (options as Option[]).find((option) => option.value === value)?.label;
      })
      .filter(Boolean)
      .join(', ');
  };

  return (
    <div className={`multi-select ${className} ${disabled ? 'disabled' : ''}`} ref={containerRef}>
      {label && <label className="multi-select__label">{label}</label>}
      <div className={`multi-select__trigger ${isOpen ? 'open' : ''}`} onClick={() => !disabled && setIsOpen(!isOpen)}>
        <p className="multi-select__selected">
          {selectedValues && (Array.isArray(selectedValues) ? selectedValues.length > 0 : selectedValues) ? (
            getSelectedLabels()
          ) : (
            <span>{placeholder}</span>
          )}
        </p>
        <span className="multi-select__arrow" />
      </div>

      {isOpen && !disabled && (
        <div className="multi-select__options">
          {options.map((option) => {
            const value = typeof option === 'string' ? option : option.value;
            const label = typeof option === 'string' ? option : option.label;

            return (
              <button
                key={value}
                className={`multi-select__option ${
                  (Array.isArray(selectedValues) ? selectedValues.includes(value) : selectedValues === value)
                    ? 'selected'
                    : ''
                }`}
                onClick={() => handleOptionClick(value)}
              >
                {multiSelect && (
                  <span className="multi-select__checkbox">
                    {Array.isArray(selectedValues) && selectedValues.includes(value) && <Icon name="checkmark" />}
                  </span>
                )}
                {label}
              </button>
            );
          })}
        </div>
      )}
      <SmallErrorMsg message={errorMessage} />
    </div>
  );
};
