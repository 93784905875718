/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from 'react';
import './two-handle-slider.scss';

interface CustomSliderProps {
  min: number;
  max: number;
  minValue: number;
  maxValue: number;
  onChange: (min: number, max: number) => void;
}

const TwoHandleSlider: React.FC<CustomSliderProps> = ({ min, max, minValue, maxValue, onChange }) => {
  const [minHandleValue, setMinHandleValue] = useState(minValue);
  const [maxHandleValue, setMaxHandleValue] = useState(maxValue);
  const [isDraggingMin, setIsDraggingMin] = useState(false);
  const [isDraggingMax, setIsDraggingMax] = useState(false);

  const rangeRef = useRef<HTMLDivElement>(null);

  const handleMove = (e: MouseEvent | TouchEvent) => {
    const clientX = 'touches' in e ? e.touches[0].clientX : e.clientX;
    const newValue = calculateValue(clientX);

    if (isDraggingMin) {
      if (newValue >= min && newValue < maxHandleValue) {
        setMinHandleValue(newValue);
      }
    }

    if (isDraggingMax) {
      if (newValue <= max && newValue > minHandleValue) {
        setMaxHandleValue(newValue);
      }
    }
  };

  const handleEnd = () => {
    setIsDraggingMin(false);
    setIsDraggingMax(false);
  };

  const handleMinStart = (e: React.MouseEvent | React.TouchEvent) => {
    setIsDraggingMin(true);
  };

  const handleMaxStart = (e: React.MouseEvent | React.TouchEvent) => {
    setIsDraggingMax(true);
  };

  const calculateValue = (clientX: number): number => {
    const rangeWidth = rangeRef.current?.offsetWidth || 0;
    const offsetX = clientX - (rangeRef.current?.getBoundingClientRect().left || 0);
    const percentage = offsetX / rangeWidth;
    return Math.min(Math.max(Math.round(percentage * (max - min) + min), min), max);
  };

  useEffect(() => {
    onChange(minHandleValue, maxHandleValue);
  }, [minHandleValue, maxHandleValue]);

  useEffect(() => {
    const handleGlobalMove = (e: MouseEvent | TouchEvent) => handleMove(e);
    const handleGlobalEnd = () => handleEnd();

    if (isDraggingMin || isDraggingMax) {
      document.addEventListener('mousemove', handleGlobalMove);
      document.addEventListener('mouseup', handleGlobalEnd);
      document.addEventListener('touchmove', handleGlobalMove, { passive: false });
      document.addEventListener('touchend', handleGlobalEnd);
    }

    return () => {
      document.removeEventListener('mousemove', handleGlobalMove);
      document.removeEventListener('mouseup', handleGlobalEnd);
      document.removeEventListener('touchmove', handleGlobalMove);
      document.removeEventListener('touchend', handleGlobalEnd);
    };
  }, [isDraggingMin, isDraggingMax]);

  return (
    <div className="slider-container">
      <div ref={rangeRef} className="slider-track">
        <div
          className="slider-range"
          style={{
            left: `${((minHandleValue - min) / (max - min)) * 100}%`,
            right: `${100 - ((maxHandleValue - min) / (max - min)) * 100}%`,
          }}
        />
        <div
          className="slider-thumb min-thumb"
          style={{ left: `calc(${((minHandleValue - min) / (max - min)) * 100}% - 10px)` }}
          onMouseDown={handleMinStart}
          onTouchStart={handleMinStart}
        />
        <div
          className="slider-thumb max-thumb"
          style={{ left: `calc(${((maxHandleValue - min) / (max - min)) * 100}% - 10px)` }}
          onMouseDown={handleMaxStart}
          onTouchStart={handleMaxStart}
        />
      </div>
      <div className="slider-values">
        <span>{minHandleValue}</span>
        <span>{maxHandleValue}</span>
      </div>
    </div>
  );
};

export default TwoHandleSlider;
