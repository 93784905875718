import { useState } from 'react';
import { useParams } from 'react-router-dom';
import LoadingFallback from '../../../../components/loading-fallback/loading-fallback';
import PlayerCard from '../../../../components/player-card/player-card';
import { SearchUser } from '../../../../components/search-user/search-user';
import { GET_GAMES_QUERY } from '../../../../global/gql/queries';
import { GamesData, UserData } from '../../../../global/interfaces';
import { useReneQuery } from '../../../../hooks';

import './organization-game-players.scss';

const OrganizationGamePlayers = () => {
  const params = useParams();
  const [searchData, setSearchData] = useState<UserData[]>();
  const [loading, setLoading] = useState(false);
  const { data, loading: gamesLoading } = useReneQuery<{ Games: GamesData } | undefined>(GET_GAMES_QUERY, {
    variables: { gameId: params.gameId },
  });

  const players = searchData ? searchData : data?.Games.items[0]?.players.items;

  return (
    <div className="organization-game-players">
      <SearchUser setSearchData={setSearchData} setLoading={setLoading} gameId={params.gameId} />
      {gamesLoading || loading ? (
        <LoadingFallback />
      ) : (
        <>
          <div className="organization-game-players__heading">
            <h2>
              {`${players?.length}
              ${players?.length === 1 ? 'player' : 'players'}`}
            </h2>
          </div>
          <div className="organization-game-players__main">
            {players?.map((user: UserData) => (
              <PlayerCard key={user.userId} user={user} />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default OrganizationGamePlayers;
