import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { EventTypes } from '../../../global/consts';
import { TRACK_AUTH_EVENT_MUTATIONS, UPSERT_GAME_MUTATION } from '../../../global/gql/mutations';
import { GET_GAME_GENRES, GET_ORG_GAMES_QUERY, GET_USER_QUERY } from '../../../global/gql/queries';
import { Event, GameData, UserData } from '../../../global/interfaces';
import { COLLECTIONS, GAMES, ORG } from '../../../global/routes';
import { useLazyReneQuery, useReneMutation, useReneQuery, useValidation } from '../../../hooks';
import { useFileUpload } from '../../../hooks/useFileUpload';
import { useProdEffect } from '../../../hooks/useProdEffect';
import FileUpload from '../../file-upload/file-upload';
import Icon from '../../Icon/Icon';
import Input from '../../input/input';
import Spinner from '../../spinner/spinner';
import Textarea from '../../textarea/textarea';
import { validations as getValidations } from './validations';

import Checkbox from '../../checkbox/checkbox';
import DropdownSearch from '../../dropdown-search/dropdown-search';
import './new-game-modal.scss';

type NewGameForm = {
  name: string;
  description: string;
  file?: File | undefined;
  category?: string;
  operatingSystems: string[]; // FE state
  iosUrl?: string;
  iosBundleId?: string;
  androidUrl?: string;
  androidPackageName?: string;
  webUrl?: string;
};

interface Props {
  setCloseModal: () => void;
}

const NewGameModal: React.FC<Props> = ({ setCloseModal }) => {
  const uploadFile = useFileUpload();
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [form, setForm] = useState<NewGameForm>({
    name: '',
    description: '',
    file: undefined,
    category: '',
    operatingSystems: [],
    iosUrl: '',
    iosBundleId: '',
    androidUrl: '',
    androidPackageName: '',
    webUrl: '',
  });

  const { errors, isFieldInvalid, isFormInvalid } = useValidation(getValidations(form.operatingSystems));

  const [search, { data, loading: categoriesLoading }] = useLazyReneQuery<{ GameGenreSearch: string[] }>(
    GET_GAME_GENRES,
  );
  const categories = data?.GameGenreSearch || [];

  const handleSearchChange = (value: string) => search({ variables: { gameGenreSearchTerm: value } });

  const { data: user } = useReneQuery<{ User: UserData }>(GET_USER_QUERY);

  const [trackEvent] = useReneMutation(TRACK_AUTH_EVENT_MUTATIONS, {
    onError() {},
  });

  const [addGame] = useReneMutation(UPSERT_GAME_MUTATION, {
    onCompleted(data: { UpsertGame: GameData }) {
      if (form?.file) {
        uploadFile(data.UpsertGame.image.uploadUrl, form.file).finally(() => {
          setLoading(false);
          setCloseModal();

          if (location.pathname === `/${ORG}`) {
            navigate(`${GAMES}/${data.UpsertGame.gameId}/${COLLECTIONS}`);
          }
        });
      }
    },
    refetchQueries: [GET_ORG_GAMES_QUERY],
  });

  useProdEffect(() => {
    if (user?.User.email) {
      trackEvent({
        variables: {
          distinctId: user.User.email,
          eventType: EventTypes.GAME_CREATE_STARTED,
        },
      });
    }
  }, []);

  const setFile = (files: File[]) => {
    setForm((prev) => ({ ...prev, file: files?.[0] }));
  };

  const handleFileChange = (e: any) => {
    const file = e.target.files[0];
    if (isFieldInvalid('file', { ...form, file })) return;
  };

  const handleFormChange = (e: Event['Input'] | Event['TextArea'] | Event['Select']) => {
    const { name, value } = e.target;
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  const handleAddingNewGame = () => {
    if (isFormInvalid(form)) return;
    let variables: {
      name: string;
      description: string;
      image: { extension?: string };
      isActive: boolean;
      chainSymbol?: string;
      approvedUserScopes: string;
      category?: string;
      iosData?: {
        url?: string;
        bundleId?: string;
      };
      androidData?: {
        url?: string;
        packageName?: string;
      };
      webData?: {
        url?: string;
      };
    } = {
      name: form.name.trim(),
      description: form.description.trim(),
      image: { extension: form?.file?.type.split('/')[1] },
      isActive: true,
      approvedUserScopes: 'user_all',
      category: form.category,
      iosData: form.operatingSystems.includes('IOS')
        ? {
            url: form.iosUrl,
            bundleId: form.iosBundleId,
          }
        : undefined,
      androidData: form.operatingSystems.includes('ANDROID')
        ? {
            url: form.androidUrl,
            packageName: form.androidPackageName,
          }
        : undefined,
      webData: form.operatingSystems.includes('WEB')
        ? {
            url: form.webUrl,
          }
        : undefined,
    };
    setLoading(true);
    addGame({ variables });
  };

  return (
    <div className="new-game-modal">
      <div className="new-game-modal__heading">
        <h2>New Game</h2>
        <button type="button" onClick={setCloseModal}>
          <Icon name="close" size={24} />
        </button>
      </div>
      <Input
        label="Name"
        name="name"
        placeholder="Enter game name"
        handleInput={handleFormChange}
        value={form.name}
        errorMessage={errors?.name}
      />
      <Textarea
        label="Description"
        name="description"
        value={form.description}
        handleInput={handleFormChange}
        placeholder="Enter game description"
        showCounter
        maxLength={100}
        errorMessage={errors?.description}
      />
      <FileUpload
        label="Image"
        setFiles={setFile}
        description="Upload Game Picture"
        errorMessage={errors?.file}
        handleChange={handleFileChange}
      />
      <DropdownSearch
        label="Category"
        options={categories.map((category) => ({ label: category, value: category })) || []}
        selectedValue={form.category}
        handleSelect={(value) => setForm((prev) => ({ ...prev, category: value }))}
        handleSearchChange={handleSearchChange}
        placeholder="Search for game category..."
        loading={categoriesLoading}
        name="category"
      />
      <div className="new-game-modal__checkboxes">
        <div className="new-game-modal__checkboxes__os">
          <Checkbox
            value={form.operatingSystems.includes('IOS')}
            setValue={(value) => {
              setForm((prev) => ({
                ...prev,
                operatingSystems: value
                  ? [...prev.operatingSystems, 'IOS']
                  : prev.operatingSystems.filter((os) => os !== 'IOS'),
                iosUrl: value ? prev.iosUrl : '',
                iosBundleId: value ? prev.iosBundleId : '',
              }));
            }}
          >
            iOS
          </Checkbox>
          <Input
            name="iosUrl"
            placeholder="iOS store URL"
            handleInput={handleFormChange}
            value={form.iosUrl || ''}
            errorMessage={errors?.['iosUrl']}
            disabled={!form.operatingSystems.includes('IOS')}
          />
          <Input
            name="iosBundleId"
            placeholder="Bundle ID"
            handleInput={handleFormChange}
            value={form.iosBundleId || ''}
            errorMessage={errors?.['iosBundleId']}
            disabled={!form.operatingSystems.includes('IOS')}
          />
        </div>

        <div className="new-game-modal__checkboxes__os">
          <Checkbox
            value={form.operatingSystems.includes('ANDROID')}
            setValue={(value) => {
              setForm((prev) => ({
                ...prev,
                operatingSystems: value
                  ? [...prev.operatingSystems, 'ANDROID']
                  : prev.operatingSystems.filter((os) => os !== 'ANDROID'),
                androidUrl: value ? prev.androidUrl : '',
                androidPackageName: value ? prev.androidPackageName : '',
              }));
            }}
          >
            Android
          </Checkbox>
          <Input
            name="androidUrl"
            placeholder="Android store URL"
            handleInput={handleFormChange}
            value={form.androidUrl || ''}
            errorMessage={errors?.['androidUrl']}
            disabled={!form.operatingSystems.includes('ANDROID')}
          />
          <Input
            name="androidPackageName"
            placeholder="Package Name"
            handleInput={handleFormChange}
            value={form.androidPackageName || ''}
            errorMessage={errors?.['androidPackageName']}
            disabled={!form.operatingSystems.includes('ANDROID')}
          />
        </div>

        <div className="new-game-modal__checkboxes__os">
          <Checkbox
            value={form.operatingSystems.includes('WEB')}
            setValue={(value) => {
              setForm((prev) => ({
                ...prev,
                operatingSystems: value
                  ? [...prev.operatingSystems, 'WEB']
                  : prev.operatingSystems.filter((os) => os !== 'WEB'),
                webUrl: value ? prev.webUrl : '',
              }));
            }}
          >
            Web
          </Checkbox>
          <Input
            name="webUrl"
            placeholder="Web URL"
            handleInput={handleFormChange}
            value={form.webUrl || ''}
            errorMessage={errors?.['webUrl']}
            disabled={!form.operatingSystems.includes('WEB')}
          />
        </div>
      </div>
      <div className="new-game-modal__actions">
        <button type="button" className="secondary-btn" onClick={setCloseModal} disabled={loading}>
          Cancel
        </button>
        <button type="button" className="primary-btn" onClick={handleAddingNewGame}>
          {loading ? <Spinner size="sm" /> : 'Create'}
        </button>
      </div>
    </div>
  );
};

export default NewGameModal;
