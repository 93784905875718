import { CampaignFormData } from '../../create-campaign-modal';
import Icon from '../../../../Icon/Icon';
import './review.scss';
import { useState } from 'react';
import Modal from '../../../modal';
import PreviewOfferModal from '../../../preview-offer-modal/preview-offer-modal';

interface ReviewProps {
  formData: CampaignFormData;
}

const Overview = ({ data }: { data: CampaignFormData['overview'] }) => {
  return (
    <div className="review__overview_data">
      <p>Name:</p>
      <p>{data.name}</p>
      <p>Objective:</p>
      <p>{data.objective}</p>
      <p>Market:</p>
      <p>{data.market}</p>
      <p>Countries:</p>
      <p>{data.countries}</p>
      <p>Gender:</p>
      <p>{data.gender}</p>
      <p>Age:</p>
      <p>
        {data.ageRange.min} - {data.ageRange.max}
      </p>
    </div>
  );
};

const AdsPlacement = ({ data }: { data: CampaignFormData['adsPlacement'] }) => {
  const [previewOfferId, setPreviewOfferId] = useState('');
  return (
    <div className="review__ads-placement_data">
      <div className="review__ads-placement_data_settings">
        <h5>Campaign settings</h5>
        <p>IAB Categories:</p>
        <p>Category 1</p>
        <p>Game genres:</p>
        <p>Action, Rpg, Sports</p>
        <p>Game titles:</p>
        <p>Nitro Blaze, Drag Racer 6000</p>
      </div>
      <div className="review__ads-placement_data_ads">
        <h5>Ad-specific settings</h5>
        <div className="review__ads-placement_data_ads_ad">
          <img src="" alt="" />
          <div>
            <h6>Sprite</h6>
            <p>
              Type: <span>3d branded object</span>
            </p>
            <p>
              Size: <span>Medium -4 to 40 inches in height</span>
            </p>
            <p>
              IAB Categories (includes): <span>Category 1, Category 2, Category 3...</span>
            </p>
            <div>
              <p>
                On-interaction offer: <span>ON</span>
              </p>
              <button className="btn-neutral-ghost" onClick={() => setPreviewOfferId('dasdas')}>
                Preview offer
              </button>
            </div>
          </div>
        </div>
      </div>
      <Modal isOpen={!!previewOfferId}>
        <PreviewOfferModal setIsModalOpen={setPreviewOfferId} />
      </Modal>
    </div>
  );
};

const Budget = ({ data }: { data: CampaignFormData['budget'] }) => {
  return (
    <div className="review__budget_data">
      <h5>Scheduling</h5>
      <p>
        Campaign dates: <span>Nov 1, 2024 - Nov 7, 2024</span>
      </p>
      <h5>Budget</h5>
      <div>
        <table>
          <thead>
            <tr>
              <th>Country/Market</th>
              <th>Ad type</th>
              <th>Impression price</th>
              <th>Quantity</th>
              <th>Subtotal</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>United States</td>
              <td>Display</td>
              <td>$0.50</td>
              <td>1,000</td>
              <td>$500.00</td>
            </tr>
          </tbody>
        </table>
        <p>
          Grand total: <span>$1000.00</span>
        </p>
      </div>
    </div>
  );
};

const reviewSections = [
  {
    title: 'Overview & Target Audience',
    className: 'review__overview',
    dataForm: 'overview',
    step: 1,
    component: (data: CampaignFormData) => <Overview data={data.overview} />,
  },
  {
    title: 'Ads placement',
    className: 'review__ads-placement',
    dataForm: 'adsPlacement',
    step: 3,
    component: (data: CampaignFormData) => <AdsPlacement data={data.adsPlacement} />,
  },
  {
    title: 'Budget & Scheduling',
    className: 'review__budget',
    step: 4,
    dataForm: 'budget',
    component: (data: CampaignFormData) => <Budget data={data.budget} />,
  },
];

const Review: React.FC<ReviewProps> = ({ formData }) => {
  return (
    <div className="review">
      <h2>Review & Launch</h2>
      <h3>Review your campaign details and launch when ready.</h3>
      <div className="review__sections">
        {reviewSections.map((section) => (
          <div className={section.className}>
            <div className={`${section.className}__header`}>
              <div>
                <Icon name="chevron-down" />
                <h4>{section.title}</h4>
              </div>
              <button>
                <Icon name="edit" />
              </button>
            </div>
            {section.component(formData)}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Review;
