import Checkbox from '../../../../checkbox/checkbox';
import Input from '../../../../input/input';
import { MultiSelect } from '../../../../multi-select/multi-select';
import { CampaignFormData } from '../../create-campaign-modal';
import './overview.scss';
const options = [
  'Option 1',
  'Option 2',
  'Option 3',
  'Option 4',
  'Option 5',
  'Option 6',
  'Option 7',
  'Option 8',
  'Option 9',
  'Option 10',
  'Option 11',
  'Option 12',
  'Option 13',
];
interface OverviewProps {
  data: {
    name: string;
    objective: string;
    market: string[];
    ageRange: { min: number; max: number };
    countries: string[];
    gender: string;
  };
  updateFormData: (step: keyof CampaignFormData, data: Partial<CampaignFormData[keyof CampaignFormData]>) => void;
}

const Overview: React.FC<OverviewProps> = ({ data, updateFormData }) => {
  const updateOverview = (data: Partial<CampaignFormData['overview']>) => {
    updateFormData('overview', data);
  };

  return (
    <div className="campaign-overview">
      <h2>Define your campaign</h2>
      <h3>Define Campaign Goals and Audience to Set the Foundation for Your Ads</h3>
      <div className="campaign-overview__info">
        <h4>Campaign Info</h4>
        <Input
          newStyle
          label="Name"
          placeholder="Enter your campaign name"
          value={data.name}
          handleInput={(e) =>
            updateOverview({
              name: e.target.value,
            })
          }
        />
        <MultiSelect
          label="Objective"
          options={['Brand Awareness']}
          selectedValues={data.objective}
          onChange={(selected) => updateOverview({ objective: selected as string })}
          placeholder="Select objective"
          multiSelect={false}
        />
      </div>
      <div className="campaign-overview__audience">
        <h4>Target Audience</h4>
        <div>
          <MultiSelect
            label="Market"
            options={options}
            selectedValues={data.market}
            onChange={(selected) => updateOverview({ market: selected as string[] })}
            placeholder="Select market"
            multiSelect={true}
          />
          <MultiSelect
            label="Countries"
            multiSelect={true}
            options={options}
            selectedValues={data.countries}
            onChange={(selected) => updateOverview({ countries: selected as string[] })}
            placeholder="Select countries"
          />
          <div className="campaign-overview__audience_gender">
            <label>Gender</label>
            <div>
              <Checkbox
                newStyle
                value={data.gender === 'Male'}
                setValue={(value) => updateOverview({ gender: value ? 'Male' : '' })}
              >
                Male
              </Checkbox>
              <Checkbox
                newStyle
                value={data.gender === 'Female'}
                setValue={(value) => updateOverview({ gender: value ? 'Female' : '' })}
              >
                Female
              </Checkbox>
              <Checkbox
                newStyle
                value={data.gender === 'Other'}
                setValue={(value) => updateOverview({ gender: value ? 'Other' : '' })}
              >
                Other
              </Checkbox>
            </div>
          </div>
          <div className="campaign-overview__audience_age">
            <Input
              label="From age"
              placeholder="Enter age"
              value={data.ageRange.min}
              handleInput={(e) =>
                updateOverview({
                  ageRange: { ...data.ageRange, min: Number(e.target.value) },
                })
              }
              newStyle
            />
            <Input
              label="To age"
              placeholder="Enter age"
              value={data.ageRange.max}
              handleInput={(e) =>
                updateOverview({
                  ageRange: { ...data.ageRange, max: Number(e.target.value) },
                })
              }
              newStyle
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overview;
