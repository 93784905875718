import { GET_AD_OFFERS } from '../../../global/gql/queries';
import { AdOffers } from '../../../global/interfaces';
import { useReneQuery } from '../../../hooks';

const useOffers = (adOfferId?: string) => {
  const { data, loading, refetch } = useReneQuery<{ UserAdOffers: AdOffers }>(GET_AD_OFFERS, {
    variables: { input: { adOfferId } },
  });
  const offers = data?.UserAdOffers?.items || [];
  const unviewedCount = data?.UserAdOffers?.unviewedCount || 0;

  return { offers, loading, refetch, unviewedCount };
};

export default useOffers;
