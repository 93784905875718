import { useEffect } from 'react';

export function useSlider({
  trackRef,
  dependency,
  gap,
  className,
}: {
  gap: number;
  trackRef: React.RefObject<HTMLDivElement>;
  dependency: any;
  className: string;
}) {
  useEffect(() => {
    const slides = Array.from(trackRef.current?.children || []) as HTMLDivElement[];
    const slideWidth = slides[0]?.getBoundingClientRect()?.width + gap;
    slides.forEach((slide, index) => {
      slide.style.left = `${slideWidth * index}px`;
    });
  }, [dependency, gap, trackRef]);

  const moveToSlide = (currentSlide: HTMLDivElement, targetSlide: HTMLDivElement) => {
    if (trackRef.current) {
      trackRef.current.style.transition = 'transform 0.5s ease-in-out';
      trackRef.current.style.transform = `translateX(-${targetSlide.style.left})`;
      currentSlide.classList.remove(className);
      targetSlide.classList.add(className);
    }
  };

  const handleNext = () => {
    const currentSlide = trackRef.current?.querySelector(`.${className}`) as HTMLDivElement;
    const nextSlide = currentSlide?.nextElementSibling as HTMLDivElement;
    if (nextSlide) {
      moveToSlide(currentSlide, nextSlide);
    }
  };

  const handlePrev = () => {
    const currentSlide = trackRef.current?.querySelector(`.${className}`) as HTMLDivElement;
    const prevSlide = currentSlide?.previousElementSibling as HTMLDivElement;
    if (prevSlide) {
      moveToSlide(currentSlide, prevSlide);
    }
  };

  return { handleNext, handlePrev };
}
