import { Route, Routes } from 'react-router-dom';
import { OFFERS } from '../../../global/routes';
import GamerGamePage from '../gamer-game-page/gamer-game-page';
import GamerOffersPage from '../gamer-offers-page/gamer-offers-page';
import GamerDashboardPage from '../gamer-dashboard-page/gamer-dashboard-page';
import GamerOfferDetailsPage from '../gamer-offers-page/gamer-offer-details-page/gamer-offer-details-page';
import './gamer-view-page.scss';

const GamerViewPage = () => {
  return (
    <div className="gamer-view-page">
      <Routes>
        <Route index element={<GamerDashboardPage />} />
        <Route path={':gameId'} element={<GamerGamePage />} />
        <Route path={OFFERS} element={<GamerOffersPage />} />
        <Route path={`${OFFERS}/:offerId`} element={<GamerOfferDetailsPage />} />
      </Routes>
    </div>
  );
};

export default GamerViewPage;
