import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AssetTypeId, UserRole } from '../../../global/consts';
import { GET_OWNABLE_ASSETS_QUERY, GET_USER_QUERY } from '../../../global/gql/queries';
import { OwnableAssetData, MenuItems, UserData } from '../../../global/interfaces';
import { OWNABLE_ASSETS, ORG, GAMES, COLLECTIONS } from '../../../global/routes';
import { useReneQuery } from '../../../hooks';
import { isRoleAllowed } from '../../../utils';
import Data from './tabs/ownable-asset-metadata-tab/data/data';
import Template from './tabs/ownable-asset-metadata-tab/template/template';
import AsideMenu from '../../../components/aside-menu/aside-menu';
import Attributes from './tabs/ownable-asset-metadata-tab/attributes/attributes';
import AdSurfaceList from '../../../components/asset/ad-surface-list/ad-surface-list';
import AsidePopupMenu from '../../../components/aside-popup-menu/aside-popup-menu';
import GameEngineFiles from '../../../components/asset/game-engine-files/game-engine-files';
import OwnableAssetBanner from '../../../components/banners/ownable-asset-banner/ownable-asset-banner';

import './organization-ownable-asset-page.scss';

const sideMenuSections: { [key: string]: { title: string; items: MenuItems } } = {
  files: {
    title: 'Game engine',
    items: {
      files: { name: 'files', icon: 'file', label: 'Files' },
    },
  },
  asset: {
    title: 'Asset',
    items: {
      surface: { name: 'surface', icon: 'pip', label: 'Surface' },
    },
  },
  metadata: {
    title: 'Metadata',
    items: {
      attribute: { name: 'attribute', icon: 'stars', label: 'Attribute' },
      template: { name: 'template', icon: 'template', label: 'Template' },
      data: { name: 'data', icon: 'database', label: 'Data' },
    },
  },
};

const OrganizationOwnableAssetPage = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const { data, refetch } = useReneQuery<{
    OwnableAssets: { items: OwnableAssetData[] };
  }>(GET_OWNABLE_ASSETS_QUERY, {
    variables: { ownableAssetId: params.assetId },
  });
  const { data: user } = useReneQuery<{ User: UserData }>(GET_USER_QUERY);

  const isUserAllowedToUpsert = isRoleAllowed(user?.User.role as UserRole, [UserRole.DEVELOPER]);

  const handleMenuItemChange = (value: string) => {
    navigate(
      `/${ORG}/${GAMES}/${params.gameId}/${COLLECTIONS}/${params.collectionId}/${OWNABLE_ASSETS}/${params.assetId}/${value}`,
    );
  };

  const renderSelectedMenuItem = (selectedMenuItem: string) => {
    switch (selectedMenuItem) {
      case 'files':
        return <GameEngineFiles type={AssetTypeId.ownableAssetId} setOpenMobileMenu={setIsMobileMenuOpen} />;
      case 'attribute':
        return (
          <Attributes
            isUserAllowedToUpsert={isUserAllowedToUpsert}
            attributes={data?.OwnableAssets?.items[0].attributes}
            ownableAssetId={params.assetId as string}
            updatedAt={data?.OwnableAssets?.items[0].updatedAt as string}
            setOpenMobileMenu={setIsMobileMenuOpen}
            refetch={refetch}
          />
        );
      case 'template':
        return (
          <Template
            isUserAllowedToUpsert={isUserAllowedToUpsert}
            ownableAsset={data?.OwnableAssets?.items[0]}
            setOpenMobileMenu={setIsMobileMenuOpen}
            refetch={refetch}
          />
        );
      case 'data':
        return (
          <Data
            ownableAsset={data?.OwnableAssets?.items[0]}
            isUserAllowedToUpsert={isUserAllowedToUpsert}
            setOpenMobileMenu={setIsMobileMenuOpen}
            refetch={refetch}
          />
        );
      case 'surface':
        return (
          <AdSurfaceList
            id={params.assetId}
            type="ownableAssetId"
            isUserAllowedToUpsert={isUserAllowedToUpsert}
            setOpenMobileMenu={setIsMobileMenuOpen}
          />
        );
      default:
        break;
    }
  };

  return (
    <div className="organization-ownable-asset-page">
      <OwnableAssetBanner ownableAsset={data?.OwnableAssets.items[0]} refetch={refetch} />
      <div className="organization-ownable-asset-page__main">
        <AsidePopupMenu isMenuOpen={isMobileMenuOpen} setIsOpen={setIsMobileMenuOpen}>
          <div className="organization-ownable-asset-page__main_aside">
            {Object.keys(sideMenuSections).map((key) => {
              const { title, items } = sideMenuSections[key];

              return (
                <div key={title}>
                  <h1 className="rainbow-btn-text">{title}</h1>
                  <AsideMenu
                    items={items}
                    selectedItem={params.menuItem as string}
                    handleItemSelect={handleMenuItemChange}
                  />
                </div>
              );
            })}
          </div>
        </AsidePopupMenu>
        <div className="organization-ownable-asset-page__main_selected">
          {renderSelectedMenuItem(params.menuItem as string)}
        </div>
      </div>
    </div>
  );
};

export default OrganizationOwnableAssetPage;
