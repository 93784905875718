import { isAfter, isToday, differenceInYears, addYears, isBefore, isPast } from 'date-fns';
import { commonValidations } from '../../../../global/validations';

export const validations = {
  name: commonValidations.requiredTrimmed(),
  description: commonValidations.requiredTrimmed(),
  file: (form: { [k: string]: any }, key: 'file') => {
    if (form.campaignImage && !form.campaignImage.fileId) {
      return commonValidations.fileImage()(form, key);
    }
  },
  ageFrom: (form: { [key: string]: any }, _key: string, field: number) => {
    if (!field && field !== 0) return 'This field is required';
    if (field < 0 || field > 100) return 'Age must be between 0 and 100';
    if (field >= form.ageTo) return 'Age From should be less than Age To';
  },
  ageTo: (form: { [key: string]: any }, _key: string, field: number) => {
    if (!field && field !== 0) return 'This field is required';
    if (field < 0 || field > 100) return 'Age must be between 0 and 100';
    if (field <= form.ageFrom) return 'Age To should be greater than Age From';
  },
  gender: commonValidations.nonEmptyList(),
  startDate: (form: { [key: string]: any }, _key: string, field: string) => {
    if (!field) return 'This field is required';
    if (form.isEdit) return;
    const startDate = new Date(field);
    const endDate = new Date(form.endDate);
    const maxFutureDate = addYears(new Date(), 5);
    if (isAfter(startDate, endDate)) return 'Start date cannot be after the end date';
    if (!isToday(startDate) && !isAfter(startDate, new Date())) return 'The date cannot be in the past';
    if (!isBefore(startDate, maxFutureDate)) return 'Start date cannot be more then 5 years in the future';
  },
  endDate: (form: { [key: string]: any }, _key: string, field: string) => {
    if (!field) return 'This field is required';
    const endDate = new Date(field);
    if (!isAfter(endDate, new Date())) return 'The End date needs to be in the future';
    if (differenceInYears(endDate, new Date(form.startDate)) > 0) return 'Date interval cannot be more than a year';
  },
  editStartDate: (value: string) => {
    if (isPast(new Date(value))) return 'The Start date needs to be in the future';
  },
  targetMarket: commonValidations.requiredTrimmed(),
  // TODO: Add brandedAssetIds checks
  /* brand: (form: { campaignGoal: CampaignGoal }, _key: string, brand: string) => {
    if (form.campaignGoal === CampaignGoal.BRAND_AWARENESS && !brand) return 'This field is required';
  }, */
  totalImpressions: (form: { [key: string]: any }, _key: string, field: string) => {
    if (!field) return 'This field is required';
  },
  noFunds: (form: { [key: string]: any }) => {
    if (form.selectedImpressionsPrice > form.balance)
      return `Insufficient funds. Please add $${form.selectedImpressionsPrice - form.balance}`;
  },
  web3Only: (_form: { [key: string]: any }, _key: string, field: boolean) => {
    if (typeof field !== 'boolean') return 'Invalid value for Web3-only field';
  },
};
