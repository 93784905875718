import { useCallback, useState } from 'react';
import { CampaignFormData } from '../../create-campaign-modal';
import Icon from '../../../../Icon/Icon';
import Search from '../../../../search/search';
import Checkbox from '../../../../checkbox/checkbox';
import CreateAdModal from '../../../create-ad-modal/create-ad-modal';

import './select-ad.scss';
import Modal from '../../../modal';

interface SelectAdProps {
  data: CampaignFormData['selectedAds'];
  updateFormData: (step: keyof CampaignFormData, data: Partial<CampaignFormData[keyof CampaignFormData]>) => void;
}

const SelectAd: React.FC<SelectAdProps> = ({ data, updateFormData }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [adCampaignSearchTerm, setSearchTerm] = useState('');
  const [selectedFilter, setSelectedFilter] = useState<'all' | 'branded-objects' | 'surface'>('all');

  const handleSearch = useCallback((data: string) => {
    setSearchTerm(data);
  }, []);
  console.log(adCampaignSearchTerm);

  return (
    <div className="select-ad">
      <h2>Select your ads</h2>
      <h3>Choose existing ads from your library or upload new.</h3>
      <div className="select-ad__nav">
        <Search callback={handleSearch} apiSearch />
        <div className="select-ad__nav_filters">
          <button onClick={() => setSelectedFilter('all')} className={selectedFilter === 'all' ? 'active' : ''}>
            All
          </button>
          <button
            onClick={() => setSelectedFilter('branded-objects')}
            className={selectedFilter === 'branded-objects' ? 'active' : ''}
          >
            Branded Objects
          </button>
          <button onClick={() => setSelectedFilter('surface')} className={selectedFilter === 'surface' ? 'active' : ''}>
            Surface
          </button>
        </div>
        <button className="btn-primary-ghost" onClick={() => setIsModalOpen(true)}>
          <Icon name="plus" />
          New Ad
        </button>
      </div>
      <div className="select-ad__ads">
        <div className="select-ad__ads_heading">
          <div>
            <p>Library</p>
            <Icon size={20} name="chevron-up" />
          </div>
          <div>
            <Checkbox newStyle value={false} setValue={() => {}} />
            <p>Select all</p>
          </div>
        </div>
        <div className="select-ad__ads_list">
          {new Array(1).fill(10).map((_, index) => (
            <div className="select-ad__ads_list_item">
              <div>
                <Checkbox newStyle value={false} setValue={() => {}} />
                <img src="" alt="" />
              </div>
              <p>Sprite</p>
              <p>Branded Object</p>
            </div>
          ))}
        </div>
      </div>
      <Modal isOpen={isModalOpen}>
        <CreateAdModal setIsModalOpen={setIsModalOpen} />
      </Modal>
    </div>
  );
};

export default SelectAd;
