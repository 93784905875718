import { useState } from 'react';
import { Event } from '../../global/interfaces';
import './textarea.scss';

interface Props extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  newStyle?: boolean;
  label?: string;
  name?: string;
  value: string | undefined;
  className?: string;
  handleInput: (e: Event['TextArea']) => void;
  placeholder?: string;
  maxLength?: number | undefined;
  showCounter?: boolean;
  textAreaRef?: React.RefObject<HTMLTextAreaElement>;
  errorMessage?: string;
  props?: React.TextareaHTMLAttributes<HTMLTextAreaElement>;
}

const Textarea: React.FC<Props> = ({
  newStyle,
  label = null,
  name = '',
  className = '',
  value,
  handleInput,
  placeholder,
  maxLength,
  showCounter,
  textAreaRef,
  errorMessage,
  ...props
}) => {
  const [descriptionCounter, setDescriptionCounter] = useState<number>(0);
  const [prevValue, setPrevValue] = useState<string>();

  if (!prevValue && value) {
    setPrevValue(value);
    setDescriptionCounter(value.length);
  }

  const handleDescriptionChange = (e: Event['TextArea']) => {
    handleInput(e);

    if (maxLength && e.target.value.length > maxLength) return;
    setDescriptionCounter(e.target.value.length);
  };

  return (
    <div className={`textarea-container${newStyle ? '-new' : ''} ${className}`}>
      {label ? <label>{label}</label> : null}
      <textarea
        ref={textAreaRef}
        name={name}
        placeholder={placeholder}
        maxLength={maxLength}
        onChange={handleDescriptionChange}
        value={value}
        {...props}
      />
      <div>
        {showCounter ? <p className="textarea-container__counter">{descriptionCounter}/100</p> : null}
        <p className="textarea-container__error">{errorMessage}</p>
      </div>
    </div>
  );
};

export default Textarea;
